import { db, auth, storage } from "../../config/firebase-config";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  FirebaseStorage,
} from "firebase/storage";

/**
 * Old code which has gs / https protocol issue.
 */
export const _uploadFile = async (file: File) => {
  const timestamp = Date.now(); // Get the current timestamp
  const fileNameWithTimestamp = `${timestamp}_${file.name}`;
  const storageRef = ref(storage, fileNameWithTimestamp);
  await uploadBytes(storageRef, file);
  return getDownloadURL(storageRef);
};

export const uploadFile = async (file: File | null | undefined) => {
  if (!file) return;
  const timestamp = Date.now(); // Get the current timestamp
  const fileNameWithTimestamp = `${timestamp}_${file.name}`;
  const storageRef = ref(storage, fileNameWithTimestamp);
  const _result = await uploadBytes(storageRef, file);
  const _url = getDownloadURL(storageRef);
  const httpLink = storageRef.toString();
  // const storageRef2 = ref(storage, httpLink);
  // console.info("#3: ", getDownloadURL(storageRef2), "\n");
  return httpLink;
};

export const gsToHttps = async (_link: string | null | undefined) => {
  if (!_link) return "";
  const link = decodeURIComponent(_link);
  if (link.startsWith("https://") || link.startsWith("http://")) {
    return link;
  }
  const storageRef = ref(storage, link);
  const url = decodeURIComponent(await getDownloadURL(storageRef));
  return url;
};
/**
 the converter has 4 steps
//1
source_https_url = "https://firebasestorage.googleapis.com/v0/b/bremencityar-16a5d.appspot.com/o/1734431285426_DALL·E 2024-12-16 10.47.47 - A 3D visualization of a circular plate floating in the air, viewed from a slightly front-facing angle as if it is hovering at chest level. The surface.webp?alt=media&token=8875d654-2a88-4b2d-985a-3af6862ebd4a"
 
//2 remove substring after "?"
trimmed_https_url = "https://firebasestorage.googleapis.com/v0/b/bremencityar-16a5d.appspot.com/o/1734431285426_DALL·E 2024-12-16 10.47.47 - A 3D visualization of a circular plate floating in the air, viewed from a slightly front-facing angle as if it is hovering at chest level. The surface.webp
 
//3 remove substring before "https://firebasestorage.googleapis.com/v0/b/bremencityar-16a5d.appspot.com/o/"
trimmed_https_url = "1734431285426_DALL·E 2024-12-16 10.47.47 - A 3D visualization of a circular plate floating in the air, viewed from a slightly front-facing angle as if it is hovering at chest level. The surface.webp"
 
//4
gs_format = `gs://bremencityar-16a5d.appspot.com/${https_url}`

example:

- https://firebasestorage.googleapis.com/v0/b/bremengo-staging.appspot.com/o/1737971889033_a.mp3?alt=media&token=b83858fe-56e8-4a72-bed2-a6c23008f159
- gs://bremengo-staging.appspot.com/1737971889033_a.mp3
 */
export const httpsToGs = (link: string | null | undefined) => {
  if (!link) return link ?? "";
  if (link.startsWith("gs://")) return decodeURIComponent(link);
  try {
    const sourceUrl = decodeURIComponent(link);
    const firstIndex = sourceUrl.indexOf(".com") + 1;
    var prefixPath =
      sourceUrl.substring(0, sourceUrl.indexOf(".com", firstIndex)) + ".com";
    const domain = prefixPath.split("/").pop() ?? "";
    var fileName = sourceUrl.replace(
      sourceUrl.substring(0, sourceUrl.indexOf(domain, firstIndex)) + domain,
      ""
    );
    fileName = fileName.split("?")[0];
    fileName = fileName.split("/")[2];
    const finalLink = `gs://${domain}/${fileName}`;
    return finalLink;
  } catch (e) {
    return link;
  }
};
