import React, { ChangeEvent, ChangeEventHandler } from "react";
import { Helmet } from "react-helmet";
import { NavbarWithMegaMenu } from "../components/navbar";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  TrashIcon,
  PencilSquareIcon,
  PlusCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import {
  Button,
  Checkbox,
  Typography,
  Tooltip,
  Textarea,
  Spinner,
  Input,
} from "@material-tailwind/react";
import { FileInput } from "flowbite-react";
import DeuFlagImg from "../../images/flags/deuflage.png";
import AMFlagImg from "../../images/flags/amflag.png";
import { useState, useEffect, useRef } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../config/firebase-config";
import { useDrag, useDrop } from "react-dnd";
import {
  handleAudioInputFileChange,
  handleImageInputFileChange,
  handleImagesInputFileChange,
  IAudio,
  IImages,
  removeImageFromState,
  resetAudio,
  updateImageDescriptionChange,
  updateImageIsModalOpen,
} from "src/utils/form/updateFieldStateUtil";
import { IPoi } from "src/features/firebase/dataStructure/Poi";
import {
  gsToHttps,
  httpsToGs,
  uploadFile,
} from "src/utils/firebase/firebaseUtil";
const ItemTypes = {
  CARD: "card",
};
const EditPoiPage = () => {
  const [error, setError] = useState(false);
  const moveItem = (dragIndex: number, hoverIndex: number) => {
    const draggedItem = oldImages[dragIndex];
    const newSortedItems = [...oldImages];
    newSortedItems.splice(dragIndex, 1);
    newSortedItems.splice(hoverIndex, 0, draggedItem);
    setOldImages(newSortedItems);
  };
  const Item = ({ item, index }) => {
    const [{ isDragging }, drag] = useDrag({
      type: ItemTypes.CARD,
      item: { type: ItemTypes.CARD, index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const [, drop] = useDrop({
      accept: ItemTypes.CARD,
      hover: (item: any) => {
        const dragIndex = item.index;
        const hoverIndex = index;

        if (dragIndex === hoverIndex) {
          return;
        }

        moveItem(dragIndex, hoverIndex);
        item.index = hoverIndex;
      },
    });

    // const opacity = isDragging ? 0.5 : 1;

    return (
      <div
        ref={(node) => drag(drop(node))}
        className={`col-span-3 ${isDragging ? "opacity-50" : ""}`}
        style={{ cursor: "move" }}
      >
        <div>
          <img
            src={item.imageUrl}
            alt=""
            className="cursor-pointer"
            onClick={() => modalToggleOld(index, true)}
          />
        </div>
        {item.isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <img
              src={item.imageUrl}
              alt="Full Size Preview"
              className="max-w-full max-h-full rounded-lg shadow-xl"
            />
            <button
              className="absolute top-4 right-4 bg-white text-black p-2 rounded-full shadow-md"
              onClick={() => modalToggleOld(index, false)}
            >
              ✕
            </button>
          </div>
        )}
        <div className="grid grid-cols-12 gap-1 mt-1">
          <div className="flex">
            <img src={DeuFlagImg} alt="" />
          </div>
          <div className="col-span-11">
            {/* @ts-ignore */}
            <Input
              className="bg-white"
              icon={<PencilSquareIcon />}
              value={item.imageDescriptionDe}
              onChange={(e) => handleOldImageDeChange(index, e)}
            />
          </div>
        </div>
        <div className="grid grid-cols-12 gap-1 mt-1">
          <div className="flex">
            <img src={AMFlagImg} alt="" />
          </div>
          <div className="col-span-11">
            {/* @ts-ignore */}
            <Input
              className="bg-white"
              icon={<PencilSquareIcon />}
              value={item.imageDescriptionEn}
              onChange={(e) => handleOldImageEnChange(index, e)}
            />
          </div>
        </div>
        <div className="mt-1"></div>
        <TrashIcon
          strokeWidth={2}
          className="h-6 w-6 text-[#5A5A5A]  cursor-pointer m-auto mt-1"
          onClick={(e) => {
            removeOldImage(index);
          }}
        ></TrashIcon>
      </div>
    );
  };
  const navigate = useNavigate();
  const audioRefDe = useRef<HTMLAudioElement>(null);
  const audioRefEn = useRef<HTMLAudioElement>(null);
  const storyRefDe = useRef<HTMLAudioElement>(null);
  const storyRefEn = useRef<HTMLAudioElement>(null);
  const [loading, setLoading] = useState(false);
  const [poiID, setPoiID] = useState<string>("");

  const [firstTextHeadingDe, setFirstTextHeadingDe] = useState<
    string | null | undefined
  >("");
  const [firstTextHeadingEn, setFirstTextHeadingEn] = useState<
    string | null | undefined
  >("");
  const [nameDe, setNameDe] = useState<string | null | undefined>("");
  const [nameEn, setNameEn] = useState<string | null | undefined>("");
  const [addressDe, setAddressDe] = useState<string | null | undefined>("");
  const [addressEn, setAddressEn] = useState<string | null | undefined>("");
  const [descEn, setDescEn] = useState<string | null | undefined>("");
  const [descDe, setDescDe] = useState<string | null | undefined>("");
  const [published, setPublished] = useState<boolean | undefined>(false);

  const [firstTextHeadingDeError, setFirstTextHeadingDeError] = useState(false);
  const [firstTextHeadingEnError, setFirstTextHeadingEnError] = useState(false);
  const [nameDeError, setNameDeError] = useState(false);
  const [nameEnError, setNameEnError] = useState(false);
  const [descDeError, setDescDeError] = useState(false);
  const [descEnError, setDescEnError] = useState(false);

  const [tipDescDe, setTipDescDe] = useState<string | null | undefined>("");
  const [tipDescEn, setTipDescEn] = useState<string | null | undefined>("");
  const [tipLinkDe, setTipLinkDe] = useState<string | null | undefined>("");
  const [tipLinkEn, setTipLinkEn] = useState<string | null | undefined>("");

  const [willbeHidden, setWillbeHidden] = useState<boolean | undefined>(false);
  const [isAR, setIsAR] = useState<boolean | undefined>(false);
  const [isPanorama, setIsPanorama] = useState<boolean | undefined>(false);
  const [GPS, setGPS] = useState<string | null | undefined>("");
  const [GPSError, setGPSError] = useState<boolean | undefined>(false);
  const [facebookDe, setFacebookDe] = useState<string | null | undefined>("");
  const [facebookEn, setFacebookEn] = useState<string | null | undefined>("");
  const [instagramDe, setInstagramDe] = useState<string | null | undefined>("");
  const [instagramEn, setInstagramEn] = useState<string | null | undefined>("");
  const [weblinkDe, setWeblinkDe] = useState<string | null | undefined>("");
  const [weblinkEn, setWeblinkEn] = useState<string | null | undefined>("");
  const [videoNameDe, setVideoNameDe] = useState<string | null | undefined>("");
  const [videoNameEn, setVideoNameEn] = useState<string | null | undefined>("");
  const [videoLinkDe, setVideoLinkDe] = useState<string | null | undefined>("");
  const [videoLinkEn, setVideoLinkEn] = useState<string | null | undefined>("");
  const [fileDe, setFileDe] = useState<File | null>(null);
  const [fileEn, setFileEn] = useState<File | null>(null);
  const [tipDe, setTipDe] = useState<File | null>(null);
  const [tipEn, setTipEn] = useState<File | null>(null);
  const [storyDe, setStoryDe] = useState<File | null>(null);
  const [storyEn, setStoryEn] = useState<File | null>(null);
  const [thumbDe, setThumbDe] = useState<File | null>(null);

  const [audioSrcDe, setAudioSrcDe] = useState<IAudio>(null);
  const [audioSrcEn, setAudioSrcEn] = useState<IAudio>(null);
  const [storySrcDe, setStorySrcDe] = useState<IAudio>(null);
  const [storySrcEn, setStorySrcEn] = useState<IAudio>(null);

  const [thumbSrcDe, setThumbSrcDe] = useState<IAudio>(null);
  const [isModalOpenThumb, setIsModalOpenThumb] = useState<boolean>(false);
  const [tipSrcDe, setTipSrcDe] = useState<IAudio>(null);
  const [isModalOpenTip, setIsModalOpenTip] = useState(false);

  const [arImageSrcDe, setARImageSrcDe] = useState<IAudio>(null);
  const [isModalOpenAR, setIsModalOpenAR] = useState(false);
  const [panoramaImageSrcDe, setPanoramaImageSrcDe] = useState<IAudio>(null);
  const [isModalOpenPanorama, setIsModalOpenPanorama] = useState(false);

  // const [thumbEn, setThumbEn] = useState(null);
  const [fileARImage, setFileARImage] = useState<File | null>(null);
  const [filePanoramaImage, setFilePanoramaImage] = useState<File | null>(null);

  const [images, setImages] = useState<IImages[]>([]);

  const [oldImages, setOldImages] = useState<
    {
      imageUrl: string;
      imageFile: null;
      imageDescriptionDe?: string;
      imageDescriptionEn?: string;
      isModalOpen: boolean;
    }[]
  >([]);
  const [fileDeUrl, setFileDeUrl] = useState<string | null | undefined>("");
  const [isFileDe, setIsFileDe] = useState(false);
  const [fileEnUrl, setFileEnUrl] = useState<string | null | undefined>("");
  const [isFileEn, setIsFileEn] = useState(false);
  const [tipDeUrl, setTipDeUrl] = useState<string | null | undefined>("");
  const [isTipDe, setIsTipDe] = useState(false);
  const [storyDeUrl, setStoryDeUrl] = useState<string | null | undefined>("");
  const [isStoryDe, setIsStoryDe] = useState(false);
  const [storyEnUrl, setStoryEnUrl] = useState<string | null | undefined>("");
  const [isStoryEn, setIsStoryEn] = useState(false);
  const [thumbDeUrl, setThumbDeUrl] = useState<string | null | undefined>("");
  const [isThumbDe, setIsThumbDe] = useState(false);
  const [fileARImageUrl, setFileARImageUrl] = useState<
    string | null | undefined
  >("");
  const [isFileARImage, setIsFileARImage] = useState(false);
  const [filePanoramaImageUrl, setFilePanoramaImageUrl] = useState<
    string | null | undefined
  >("");
  const [isFilePanoramaImage, setIsFilePanoramaImage] = useState(false);

  // const [isModalOpenDeBasic, setIsModalOpenDeBasic] = useState(false);
  // const [isModalOpenEnBasic, setIsModalOpenEnBasic] = useState(false);
  const [isModalOpenThumbBasic, setIsModalOpenThumbBasic] = useState(false);
  const [isModalOpenTipDeBasic, setIsModalOpenTipDeBasic] = useState(false);
  const [isModalOpenARBasic, setIsModalOpenARBasic] = useState(false);
  const [isModalOpenPanoramaBasic, setIsModalPanoramaBasic] = useState(false);
  const modalToggleARBasic = (isOpen: boolean) => setIsModalOpenARBasic(isOpen);

  const modalTogglePanoramaBasic = (isOpen: boolean) =>
    setIsModalPanoramaBasic(isOpen);
  // const modalToggleDeBasic = (isOpen: boolean) => setIsModalOpenDeBasic(isOpen);
  // const modalToggleEnBasic = (isOpen: boolean) => setIsModalOpenEnBasic(isOpen);
  const modalToggleThumbBasic = (isOpen: boolean) =>
    setIsModalOpenThumbBasic(isOpen);

  const modalToggleTipDeBasic = (isOpen: boolean) =>
    setIsModalOpenTipDeBasic(isOpen);

  const extractFileName = (url: string) => {
    const path = url.split("?")[0]; // Removes query parameters
    const fileName = path.substring(path.lastIndexOf("/") + 1);
    return decodeURIComponent(fileName); // Decodes the URL-encoded characters
  };
  useEffect(() => {
    async function fetchData() {
      const path = window.location.pathname;
      const id = path.split("/").pop(); // Assuming faq_id is at the end of the URL
      if (!!!id) return;
      setPoiID(id);
      const docRef = doc(db, "poi", id);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        console.log("No such document!");
        return;
      }

      const data: IPoi = docSnap.data(); // Extracts the data
      console.log(data);
      setFileDeUrl(await gsToHttps(data?.languages?.de?.firstAudio));
      setFileEnUrl(await gsToHttps(data?.languages?.en.firstAudio));
      setTipDeUrl(await gsToHttps(data.TipImage));
      setStoryDeUrl(await gsToHttps(data?.languages?.de?.audiostory));
      setStoryEnUrl(await gsToHttps(data?.languages?.en?.audiostory));
      setThumbDeUrl(await gsToHttps(data.ThumbnailImage));
      setFilePanoramaImageUrl(await gsToHttps(data.PanoramaImage));
      setFirstTextHeadingDe(data?.languages?.de?.FirstTextHeading);
      setFirstTextHeadingEn(data?.languages?.en?.FirstTextHeading);
      setNameDe(data?.languages?.de?.name);
      setNameEn(data?.languages?.en?.name);
      setAddressDe(data?.languages?.de?.POIAddress);
      setAddressEn(data?.languages?.en?.POIAddress);
      setDescDe(data?.languages?.de?.firstText);
      setDescEn(data?.languages?.en?.firstText);
      setPublished(data.published);
      setTipDescDe(data?.languages?.de?.TipDescription);
      setTipDescEn(data?.languages?.en?.TipDescription);
      setTipLinkDe(data?.languages?.de?.TipLink);
      setTipLinkEn(data?.languages?.en?.TipLink);
      setWillbeHidden(data.hideInOverview);
      setIsAR(data.arActive);
      setIsPanorama(data.arScenelsPanorama);
      setGPS(data.gps);
      setFacebookDe(data?.languages?.de?.facebookLink);
      setFacebookEn(data?.languages?.en?.facebookLink);
      setInstagramDe(data?.languages?.de?.instagramLink);
      setInstagramEn(data?.languages?.en?.instagramLink);
      setWeblinkDe(data?.languages?.de?.weblink);
      setWeblinkEn(data?.languages?.en?.weblink);
      setVideoNameDe(data?.languages?.de?.VideoName);
      setVideoNameEn(data?.languages?.en?.VideoName);
      setVideoLinkDe(data?.languages?.de?.videoLink);
      setVideoLinkEn(data?.languages?.en?.videoLink);
      setFileARImageUrl(await gsToHttps(data.ARImage));
      if (data.ARImage) setIsFileARImage(true);
      if (data.PanoramaImage) setIsFilePanoramaImage(true);
      if (data.ThumbnailImage) setIsThumbDe(true);
      if (data.TipImage) setIsTipDe(true);
      if (data?.languages?.de.audiostory) setIsStoryDe(true);
      if (data?.languages?.en.audiostory) setIsStoryEn(true);
      if (data?.languages?.de.firstAudio) setIsFileDe(true);
      if (data?.languages?.en.firstAudio) setIsFileEn(true);

      const images = await Promise.all(
        (data.images ?? []).map((image) => gsToHttps(image))
      );

      const backend_images = images.map((image, index) => {
        const imgDe = data?.languages?.de?.imagesDescription;
        const imgEn = data?.languages?.en?.imagesDescription;
        const imageDescriptionDe = imgDe ? imgDe[index] : undefined;
        const imageDescriptionEn = imgEn ? imgEn[index] : undefined;
        return {
          imageUrl: image,
          imageFile: null,
          imageDescriptionDe: imageDescriptionDe,
          imageDescriptionEn: imageDescriptionEn,
          isModalOpen: false,
        };
      });
      setOldImages(backend_images);
    }
    fetchData();
  }, []);

  const handleFileDeChange: ChangeEventHandler<HTMLInputElement> = (event) =>
    handleAudioInputFileChange(event, setFileDe, setAudioSrcDe, resetAudioDe);
  const handleFileEnChange: ChangeEventHandler<HTMLInputElement> = (event) =>
    handleAudioInputFileChange(event, setFileEn, setAudioSrcEn, resetAudioEn);
  const resetAudioDe = () => resetAudio(audioRefDe);
  const resetAudioEn = () => resetAudio(audioRefEn);
  const handleBilderChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => handleImagesInputFileChange(event, index, setImages);
  const removeImage = (index: number) =>
    removeImageFromState(index, images, setImages);
  const modalToggle = (index: number, isOpen: boolean) =>
    updateImageIsModalOpen(images, index, isOpen, setImages);
  const modalToggleOld = (index: number, isOpen: boolean) => {
    const newImages = [...oldImages]; // Create a copy of the state array
    newImages[index].isModalOpen = isOpen;
    setOldImages(newImages);
  };
  //

  const handleOldImageDeChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const newDescription = event.target.value; // Get the new description from the event
    setOldImages((prevImages) => {
      // Create a new copy of the images array
      const updatedImages = [...prevImages];
      // Update the imageDescriptionDe for the specific index
      updatedImages[index] = {
        ...updatedImages[index],
        imageDescriptionDe: newDescription,
      };
      return updatedImages; // Return the updated array
    });
  };
  const handleOldImageEnChange = (index, event) => {
    const newDescription = event.target.value; // Get the new description from the event
    setOldImages((prevImages) => {
      // Create a new copy of the images array
      const updatedImages = [...prevImages];
      // Update the imageDescriptionDe for the specific index
      updatedImages[index] = {
        ...updatedImages[index],
        imageDescriptionEn: newDescription,
      };
      return updatedImages; // Return the updated array
    });
  };
  const removeOldImage = (index: number) =>
    setOldImages((prevImages) => prevImages.filter((_, i) => i !== index));
  const handleTipDeChange: ChangeEventHandler<HTMLInputElement> = (event) =>
    handleImageInputFileChange(event, setTipDe, setTipSrcDe);

  const modalToggleTip = (isOpen: boolean) => setIsModalOpenTip(isOpen);

  // const handleTipEnChange = (event) => {
  //   const selectedFile = event.target.files[0];
  //   setTipEn(selectedFile);
  // };
  const handleThumbDeChange: ChangeEventHandler<HTMLInputElement> = (event) =>
    handleImageInputFileChange(event, setThumbDe, setThumbSrcDe);

  const modalToggleThumb = (isOpen: boolean) => setIsModalOpenThumb(isOpen);

  // const handleThumbEnChange = (event) => {
  //   const selectedFile = event.target.files[0];
  //   setThumbEn(selectedFile);
  // };
  const handleAudioStoryDeChange: ChangeEventHandler<HTMLInputElement> = (
    event
  ) =>
    handleAudioInputFileChange(event, setStoryDe, setStorySrcDe, resetStoryDe);
  const handleAudioStoryEnChange: ChangeEventHandler<HTMLInputElement> = (
    event
  ) =>
    handleAudioInputFileChange(event, setStoryEn, setStorySrcEn, resetStoryEn);
  const resetStoryDe = () => resetAudio(storyRefDe);
  const resetStoryEn = () => resetAudio(storyRefEn);

  const handleFileARImageChange = (event: ChangeEvent<HTMLInputElement>) =>
    handleImageInputFileChange(event, setFileARImage, setARImageSrcDe);
  const handleFilePanoramaImageChange = (
    event: ChangeEvent<HTMLInputElement>
  ) =>
    handleImageInputFileChange(
      event,
      setFilePanoramaImage,
      setPanoramaImageSrcDe
    );

  const modalToggleAR = (isOpen: boolean) => setIsModalOpenAR(isOpen);

  const modalTogglePanorama = (isOpen: boolean) =>
    setIsModalOpenPanorama(isOpen);

  // Function to add a new item to the linksDe array
  const addNewImage = () => {
    setImages([
      ...images,
      {
        imageUrl: "",
        imageFile: null,
        imageDescriptionDe: "",
        imageDescriptionEn: "",
        previewUrl: null,
        isModalOpen: false,
        isHidden: false,
      },
    ]); // Add a new empty string to the array
  };
  const handleInputDeChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => updateImageDescriptionChange(images, index, event, setImages, "de");

  const handleInputEnChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => updateImageDescriptionChange(images, index, event, setImages, "en");

  const onSubmit = async () => {
    if (firstTextHeadingDe === "") {
      setError(true);
      setFirstTextHeadingDeError(true);
      return;
    } else if (firstTextHeadingEn === "") {
      setError(true);
      setFirstTextHeadingEnError(true);
      return;
    } else if (nameDe === "") {
      setError(true);
      setNameDeError(true);
      return;
    } else if (nameEn === "") {
      setError(true);
      setNameEnError(true);
      return;
    } else if (descDe === "") {
      setError(true);
      setDescDeError(true);
      return;
    } else if (descEn === "") {
      setError(true);
      setDescEnError(true);
      return;
    } else if (GPS === "") {
      setGPSError(true);
      setError(true);
      return;
    }

    setLoading(true);

    const filteredImages = images.filter(
      (image) => image.imageFile !== null && image.isHidden === false
    );

    const imageUploadPromises = filteredImages.map(async (image) => {
      image.imageUrl = await uploadFile(image.imageFile);
      return image.imageUrl;
    });

    // const additionalUploads = [
    //   uploadFile(fileARImage),
    //   uploadFile(filePanoramaImage),
    //   uploadFile(storyDe),
    //   uploadFile(storyEn),
    //   uploadFile(fileDe),
    //   uploadFile(thumbDe),
    //   uploadFile(tipDe),
    //   uploadFile(fileEn),
    // ];

    // Wait for all uploads to complete
    await Promise.all([...imageUploadPromises]);

    const imageUrls: string[] = [];
    filteredImages.forEach((image) => {
      if (image.imageUrl) imageUrls.push(image.imageUrl);
    });

    // const imageUrls = filteredImages.map((image) => image.imageUrl);
    const imageDescriptionsDe: string[] = [];
    filteredImages.forEach((image) => {
      if (image.imageDescriptionDe)
        imageDescriptionsDe.push(image.imageDescriptionDe);
    });
    // const imageDescriptionsDe = filteredImages.map(
    //   (image) => image.imageDescriptionDe
    // );
    const imageDescriptionsEn: string[] = [];
    filteredImages.forEach((image) => {
      if (image.imageDescriptionEn)
        imageDescriptionsEn.push(image.imageDescriptionEn);
    });
    // const imageDescriptionsEn = filteredImages.map(
    //   (image) => image.imageDescriptionEn
    // );
    const oldImageUrls = oldImages.map((image) => image.imageUrl);
    const oldImageDescriptionsDe: string[] = [];
    oldImages.forEach((image) => {
      if (image.imageDescriptionDe)
        oldImageDescriptionsDe.push(image.imageDescriptionDe);
    });

    // const oldImageDescriptionsDe = oldImages.map(
    //   (image) => image.imageDescriptionDe
    // );
    const oldImageDescriptionsEn: string[] = [];
    oldImages.forEach((image) => {
      if (image.imageDescriptionEn)
        oldImageDescriptionsEn.push(image.imageDescriptionEn);
    });
    // const oldImageDescriptionsEn = oldImages.map(
    //   (image) => image.imageDescriptionEn
    // );
    const newImageUrls = [...oldImageUrls, ...imageUrls].map((image) =>
      httpsToGs(image)
    );
    const newImageDescriptionsDe = [
      ...oldImageDescriptionsDe,
      ...imageDescriptionsDe,
    ];
    const newImageDescriptionsEn = [
      ...oldImageDescriptionsEn,
      ...imageDescriptionsEn,
    ];
    const [
      _ARImageUrl,
      _filePanoramaImageUrl,
      _thumbDeUrl,
      _tipDeUrl,
      _storyDeUrl,
      _fileDeUrl,
      _storyEnUrl,
      _fileEnUrl,
    ] = await Promise.all([
      fileARImage && uploadFile(fileARImage),
      filePanoramaImage && uploadFile(filePanoramaImage),
      thumbDe && uploadFile(thumbDe),
      tipDe && uploadFile(tipDe),
      storyDe && uploadFile(storyDe),
      fileDe && uploadFile(fileDe),
      storyEn && uploadFile(storyEn),
      fileEn && uploadFile(fileEn),
    ]);

    const poiData: IPoi = {
      ARImage: _ARImageUrl ?? httpsToGs(fileARImageUrl),
      PanoramaImage: _filePanoramaImageUrl ?? httpsToGs(filePanoramaImageUrl),
      ThumbnailImage: _thumbDeUrl ?? httpsToGs(thumbDeUrl),
      TipImage: _tipDeUrl ?? httpsToGs(tipDeUrl),
      arActive: isAR,
      arScenelsPanorama: isPanorama,
      gps: GPS ?? "",
      images: newImageUrls, // customize
      hideInOverview: willbeHidden,
      languages: {
        de: {
          FirstTextHeading: firstTextHeadingDe,
          POIAddress: addressDe,
          TipDescription: tipDescDe,
          TipLink: tipLinkDe,
          VideoName: videoNameDe,
          audiostory: _storyDeUrl ?? httpsToGs(storyDeUrl),
          firstAudio: _fileDeUrl ?? httpsToGs(fileDeUrl),
          firstText: descDe,
          imagesDescription: newImageDescriptionsDe, // customize
          name: nameDe,
          videoLink: videoLinkDe,
          facebookLink: facebookDe,
          instagramLink: instagramDe,
          weblink: weblinkDe,
        },
        en: {
          FirstTextHeading: firstTextHeadingEn,
          POIAddress: addressEn,
          TipDescription: tipDescEn,
          TipLink: tipLinkEn,
          VideoName: videoNameEn,
          audiostory: _storyEnUrl ?? httpsToGs(storyEnUrl),
          firstAudio: _fileEnUrl ?? httpsToGs(fileEnUrl),
          firstText: descEn,
          imagesDescription: newImageDescriptionsEn, // customize
          name: nameEn,
          videoLink: videoLinkEn,
          facebookLink: facebookEn,
          instagramLink: instagramEn,
          weblink: weblinkEn,
        },
      },
      published: published,
    };

    const docRef = doc(db, "poi", poiID);
    await updateDoc(docRef, poiData as any); // This will only be called after all uploads are done
    setLoading(false);
    navigate("/poi");
  };
  return (
    <DndProvider backend={HTML5Backend}>
      <>
        {/* @ts-ignore */}
        <Helmet>
          <title>Neuen POI erstellen | BremenGo</title>
          <meta name="description" content="BremenGo Home Screen" />
        </Helmet>
        <NavbarWithMegaMenu />
        {loading && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-75">
            {/* @ts-ignore */}
            <Spinner size="3xl" color="white" />
          </div>
        )}
        <div className="border-b-4 border-[#5A5A5A] mx-5">
          <div className="container m-auto mt-20 pb-12">
            <h1 className="text-[#5A5A5A] text-3xl font-medium">
              Neuen POI erstellen
            </h1>
          </div>
        </div>
        <div className="container m-auto mt-12">
          <div className="grid lg:grid-cols-2 gap-6">
            <div>
              <div className="text-center m-auto mb-16">
                <img src={DeuFlagImg} className="m-auto" />
              </div>
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1">
                  <Tooltip content="Max. 18 Zeichen" placement="top">
                    <InformationCircleIcon
                      strokeWidth={2}
                      className="h-10 w-10 text-[#5A5A5A]"
                    ></InformationCircleIcon>
                  </Tooltip>
                </div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      Name
                    </div>
                    <div className="col-span-9">
                      <Input
                        className="bg-white"
                        icon={<PencilSquareIcon />}
                        // @ts-ignore
                        value={nameDe}
                        onChange={(e) => {
                          setNameDe(e.target.value);
                          setNameDeError(false);
                          setError(false);
                        }}
                      />
                      {nameDeError ? (
                        <p className="text-red-800">
                          Please fill out this field.
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1">
                  <Tooltip
                    content="Unbegrenzte Anzahl an Zeichen"
                    placement="top"
                  >
                    <InformationCircleIcon
                      strokeWidth={2}
                      className="h-10 w-10 text-[#5A5A5A]"
                    ></InformationCircleIcon>
                  </Tooltip>
                </div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      Überschrift
                    </div>
                    <div className="col-span-9">
                      <Input
                        className="bg-white"
                        icon={<PencilSquareIcon />}
                        // @ts-ignore
                        value={firstTextHeadingDe}
                        onChange={(e) => {
                          setFirstTextHeadingDe(e.target.value);
                          setFirstTextHeadingDeError(false);
                          setError(false);
                        }}
                      />
                      {firstTextHeadingDeError ? (
                        <p className="text-red-800">
                          Please fill out this field.
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1">
                  <Tooltip
                    content="Unbegrenzte Anzahl an Zeichen"
                    placement="top"
                  >
                    <InformationCircleIcon
                      strokeWidth={2}
                      className="h-10 w-10 text-[#5A5A5A]"
                    ></InformationCircleIcon>
                  </Tooltip>
                </div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      Adresse
                    </div>
                    <div className="col-span-9">
                      <Input
                        className="bg-white"
                        icon={<PencilSquareIcon />}
                        // @ts-ignore
                        value={addressDe}
                        onChange={(e) => {
                          setAddressDe(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1">
                  <Tooltip
                    content="Unbegrenzte Anzahl an Zeichen"
                    placement="top"
                  >
                    <InformationCircleIcon
                      strokeWidth={2}
                      className="h-10 w-10 text-[#5A5A5A]"
                    ></InformationCircleIcon>
                  </Tooltip>
                </div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      Text
                    </div>
                    <div className="col-span-9">
                      <Textarea
                        className="bg-white"
                        icon={<PencilSquareIcon />}
                        // @ts-ignore
                        value={descDe}
                        onChange={(e) => {
                          setDescDeError(false);
                          setError(false);
                          setDescDe(e.target.value);
                        }}
                      />
                      {descDeError ? (
                        <p className="text-red-800">
                          Please fill out this field.
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1">
                  <Tooltip
                    content="Vorlesetext für Barrierefreiheit, Audioformat: mp3, max. Dateigröße: 200kB"
                    placement="top"
                  >
                    <InformationCircleIcon
                      strokeWidth={2}
                      className="h-10 w-10 text-[#5A5A5A]"
                    ></InformationCircleIcon>
                  </Tooltip>
                </div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      Audio
                    </div>
                    {isFileDe ? (
                      <>
                        <div className="col-span-8">
                          {/* @ts-ignore */}
                          <Input
                            className="bg-white"
                            icon={<PencilSquareIcon />}
                            value={
                              // @ts-ignore
                              "Aktuelle Datei : " + extractFileName(fileDeUrl)
                            }
                            readOnly
                          />
                          <audio controls className="mt-2">
                            {/* @ts-ignore */}
                            <source src={fileDeUrl} type="audio/mp3" />
                            Your browser does not support the audio tag.
                          </audio>
                        </div>
                        <div className="col-span-1 items-center flex">
                          <TrashIcon
                            strokeWidth={2}
                            className="h-6 w-6 text-[#5A5A5A]  cursor-pointer m-auto"
                            onClick={(e) => {
                              setFileDeUrl("");
                              setIsFileDe(false);
                            }}
                          ></TrashIcon>
                        </div>
                      </>
                    ) : (
                      <div className="col-span-9">
                        <FileInput
                          className="text-[#5A5A5A]"
                          accept="audio/*"
                          id="file-upload"
                          onChange={handleFileDeChange}
                        />
                        {audioSrcDe && (
                          <audio ref={audioRefDe} controls className="mt-2">
                            {/* @ts-ignore */}
                            <source src={audioSrcDe} type="audio/mp3" />
                            Your browser does not support the audio tag.
                          </audio>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1">
                  <Tooltip
                    content="Audiostory, Audioformat: mp3, max. Dateigröße: 200kB"
                    placement="top"
                  >
                    <InformationCircleIcon
                      strokeWidth={2}
                      className="h-10 w-10 text-[#5A5A5A]"
                    ></InformationCircleIcon>
                  </Tooltip>
                </div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      AudioStory
                    </div>
                    {isStoryDe ? (
                      <>
                        <div className="col-span-8">
                          {/* @ts-ignore */}
                          <Input
                            className="bg-white"
                            icon={<PencilSquareIcon />}
                            value={
                              // @ts-ignore
                              "Aktuelle Datei : " + extractFileName(storyDeUrl)
                            }
                            readOnly
                          />
                          <audio controls className="mt-2">
                            {/* @ts-ignore */}
                            <source src={storyDeUrl} type="audio/mp3" />
                            Your browser does not support the audio tag.
                          </audio>
                        </div>
                        <div className="col-span-1 items-center flex">
                          <TrashIcon
                            strokeWidth={2}
                            className="h-6 w-6 text-[#5A5A5A]  cursor-pointer m-auto"
                            onClick={(e) => {
                              setStoryDeUrl("");
                              setIsStoryDe(false);
                            }}
                          ></TrashIcon>
                        </div>
                      </>
                    ) : (
                      <div className="col-span-9">
                        <FileInput
                          className="text-[#5A5A5A]"
                          accept="audio/*"
                          id="file-upload"
                          onChange={handleAudioStoryDeChange}
                        />
                        {storySrcDe && (
                          <audio ref={storyRefDe} controls className="mt-2">
                            {/* @ts-ignore */}
                            <source src={storySrcDe} type="audio/mp3" />
                            Your browser does not support the audio tag.
                          </audio>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1">
                  <Tooltip content="Video Name" placement="top">
                    <InformationCircleIcon
                      strokeWidth={2}
                      className="h-10 w-10 text-[#5A5A5A]"
                    ></InformationCircleIcon>
                  </Tooltip>
                </div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      Video Überschrift
                    </div>
                    <div className="col-span-9">
                      <Input
                        className="bg-white"
                        icon={<PencilSquareIcon />}
                        // @ts-ignore
                        value={videoNameDe}
                        onChange={(e) => {
                          setVideoNameDe(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1">
                  <Tooltip content="Video Vorschaubild" placement="top">
                    <InformationCircleIcon
                      strokeWidth={2}
                      className="h-10 w-10 text-[#5A5A5A]"
                    ></InformationCircleIcon>
                  </Tooltip>
                </div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      Video Vorschaubild
                    </div>
                    {isThumbDe ? (
                      <>
                        <div className="col-span-8">
                          <div className="relative my-2 w-[max-content]">
                            {/* Thumbnail Preview */}
                            <img
                              // @ts-ignore
                              src={thumbDeUrl}
                              alt="Preview"
                              className="w-72 h-auto rounded-lg shadow-lg cursor-pointer transition-transform hover:scale-105"
                              onClick={() => modalToggleThumbBasic(true)} // Open modal on click
                            />

                            {/* Full-Size Modal */}
                            {isModalOpenThumbBasic && (
                              <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                                <img
                                  // @ts-ignore
                                  src={thumbDeUrl}
                                  alt="Full Size Preview"
                                  className="max-w-full max-h-full rounded-lg shadow-xl"
                                />
                                <button
                                  className="absolute top-4 right-4 bg-white text-black p-2 rounded-full shadow-md"
                                  onClick={() => modalToggleThumbBasic(false)} // Close modal on click
                                >
                                  ✕
                                </button>
                              </div>
                            )}
                          </div>
                          {/* @ts-ignore */}
                          <Input
                            className="bg-white"
                            icon={<PencilSquareIcon />}
                            value={
                              // @ts-ignore
                              "Aktuelle Datei : " + extractFileName(thumbDeUrl)
                            }
                            readOnly
                          />
                        </div>
                        <div className="col-span-1 items-center flex">
                          <TrashIcon
                            strokeWidth={2}
                            className="h-6 w-6 text-[#5A5A5A]  cursor-pointer m-auto"
                            onClick={(e) => {
                              setThumbDeUrl("");
                              setIsThumbDe(false);
                            }}
                          ></TrashIcon>
                        </div>
                      </>
                    ) : (
                      <div className="col-span-9">
                        <FileInput
                          className="text-[#5A5A5A]"
                          accept="image/*"
                          id="file-upload"
                          onChange={handleThumbDeChange}
                        />
                        {thumbSrcDe && (
                          <div className="relative mt-2 w-[max-content]">
                            {/* Thumbnail Preview */}
                            <img
                              // @ts-ignore
                              src={thumbSrcDe}
                              alt="Preview"
                              className="w-72 h-auto rounded-lg shadow-lg cursor-pointer transition-transform hover:scale-105"
                              onClick={() => modalToggleThumb(true)} // Open modal on click
                            />

                            {/* Full-Size Modal */}
                            {isModalOpenThumb && (
                              <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                                <img
                                  // @ts-ignore
                                  src={thumbSrcDe}
                                  alt="Full Size Preview"
                                  className="max-w-full max-h-full rounded-lg shadow-xl"
                                />
                                <button
                                  className="absolute top-4 right-4 bg-white text-black p-2 rounded-full shadow-md"
                                  onClick={() => modalToggleThumb(false)} // Close modal on click
                                >
                                  ✕
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1">
                  <Tooltip content="Vimeo Weblink" placement="top">
                    <InformationCircleIcon
                      strokeWidth={2}
                      className="h-10 w-10 text-[#5A5A5A]"
                    ></InformationCircleIcon>
                  </Tooltip>
                </div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      Video Link
                    </div>
                    <div className="col-span-9">
                      <Input
                        className="bg-white"
                        icon={<PencilSquareIcon />}
                        // @ts-ignore
                        value={videoLinkDe}
                        onChange={(e) => {
                          setVideoLinkDe(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="text-center m-auto mb-16">
                <img src={AMFlagImg} className="m-auto" />
              </div>
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1"></div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      Name
                    </div>
                    <div className="col-span-9">
                      <Input
                        className="bg-white"
                        icon={<PencilSquareIcon />}
                        // @ts-ignore
                        value={nameEn}
                        onChange={(e) => {
                          setNameEnError(false);
                          setError(false);
                          setNameEn(e.target.value);
                        }}
                      />
                      {nameEnError ? (
                        <p className="text-red-800">
                          Please fill out this field.
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1"></div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      Überschrift
                    </div>
                    <div className="col-span-9">
                      <Input
                        className="bg-white"
                        icon={<PencilSquareIcon />}
                        // @ts-ignore
                        value={firstTextHeadingEn}
                        onChange={(e) => {
                          setFirstTextHeadingEnError(false);
                          setError(false);
                          setFirstTextHeadingEn(e.target.value);
                        }}
                      />
                      {firstTextHeadingEnError ? (
                        <p className="text-red-800">
                          Please fill out this field.
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1"></div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      Adresse
                    </div>
                    <div className="col-span-9">
                      <Input
                        className="bg-white"
                        icon={<PencilSquareIcon />}
                        // @ts-ignore
                        value={addressEn}
                        onChange={(e) => {
                          setAddressEn(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1"></div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      Text
                    </div>
                    <div className="col-span-9">
                      <Textarea
                        className="bg-white"
                        icon={<PencilSquareIcon />}
                        // @ts-ignore
                        value={descEn}
                        onChange={(e) => {
                          setDescEnError(false);
                          setError(false);
                          setDescEn(e.target.value);
                        }}
                      />
                      {descEnError ? (
                        <p className="text-red-800">
                          Please fill out this field.
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1"></div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      Audio
                    </div>
                    {isFileEn ? (
                      <>
                        <div className="col-span-8">
                          {/* @ts-ignore */}
                          <Input
                            className="bg-white"
                            icon={<PencilSquareIcon />}
                            value={
                              // @ts-ignore
                              "Aktuelle Datei : " + extractFileName(fileEnUrl)
                            }
                            readOnly
                          />
                          <audio controls className="mt-2">
                            {/* @ts-ignore */}
                            <source src={fileEnUrl} type="audio/mp3" />
                            Your browser does not support the audio tag.
                          </audio>
                        </div>
                        <div className="col-span-1 items-center flex">
                          <TrashIcon
                            strokeWidth={2}
                            className="h-6 w-6 text-[#5A5A5A]  cursor-pointer m-auto"
                            onClick={(e) => {
                              setFileEnUrl("");
                              setIsFileEn(false);
                            }}
                          ></TrashIcon>
                        </div>
                      </>
                    ) : (
                      <div className="col-span-9">
                        <FileInput
                          className="text-[#5A5A5A]"
                          accept="audio/*"
                          id="file-upload"
                          onChange={handleFileEnChange}
                        />
                        {audioSrcEn && (
                          <audio ref={audioRefEn} controls className="mt-2">
                            {/* @ts-ignore */}
                            <source src={audioSrcEn} type="audio/mp3" />
                            Your browser does not support the audio tag.
                          </audio>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1"></div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      AudioStory
                    </div>
                    {isStoryEn ? (
                      <>
                        <div className="col-span-8">
                          {/* @ts-ignore */}
                          <Input
                            className="bg-white"
                            icon={<PencilSquareIcon />}
                            value={
                              // @ts-ignore
                              "Aktuelle Datei : " + extractFileName(storyEnUrl)
                            }
                            readOnly
                          />
                          <audio controls className="mt-2">
                            {/* @ts-ignore */}
                            <source src={storyEnUrl} type="audio/mp3" />
                            Your browser does not support the audio tag.
                          </audio>
                        </div>
                        <div className="col-span-1 items-center flex">
                          <TrashIcon
                            strokeWidth={2}
                            className="h-6 w-6 text-[#5A5A5A]  cursor-pointer m-auto"
                            onClick={(e) => {
                              setStoryEnUrl("");
                              setIsStoryEn(false);
                            }}
                          ></TrashIcon>
                        </div>
                      </>
                    ) : (
                      <div className="col-span-9">
                        <FileInput
                          className="text-[#5A5A5A]"
                          accept="audio/*"
                          id="file-upload"
                          onChange={handleAudioStoryEnChange}
                        />
                        {storySrcEn && (
                          <audio ref={storyRefEn} controls className="mt-2">
                            {/* @ts-ignore */}
                            <source src={storySrcEn} type="audio/mp3" />
                            Your browser does not support the audio tag.
                          </audio>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1"></div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      Video Überschrift
                    </div>
                    <div className="col-span-9">
                      <Input
                        className="bg-white"
                        icon={<PencilSquareIcon />}
                        // @ts-ignore
                        value={videoNameEn}
                        onChange={(e) => {
                          setVideoNameEn(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1"></div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      {/* Video Vorschaubild */}
                      <br />
                      <br />
                    </div>
                    <div className="col-span-9">
                      {/* <FileInput
                      className="text-[#5A5A5A]"
                      id="file-upload"
                      onChange={handleThumbEnChange}
                    /> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1"></div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      Video Link
                    </div>
                    <div className="col-span-9">
                      <Input
                        className="bg-white"
                        icon={<PencilSquareIcon />}
                        // @ts-ignore
                        value={videoLinkEn}
                        onChange={(e) => {
                          setVideoLinkEn(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-b-4 border-[#5A5A5A] mx-5 mb-20">
          <div className="container m-auto mt-20 pb-12"></div>
        </div>
        <div className="container m-auto mt-12">
          <div className="grid lg:grid-cols-2 gap-6">
            <div>
              <h1 className="text-[#5A5A5A] text-3xl font-normal mb-12">
                Social Media Links
              </h1>
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1">
                  <Tooltip content="Facebook-Link (ID Link)" placement="top">
                    <InformationCircleIcon
                      strokeWidth={2}
                      className="h-10 w-10 text-[#5A5A5A]"
                    ></InformationCircleIcon>
                  </Tooltip>
                </div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      Facebook
                    </div>
                    <div className="col-span-9">
                      <Input
                        className="bg-white"
                        icon={<PencilSquareIcon />}
                        // @ts-ignore
                        value={facebookDe}
                        onChange={(e) => {
                          setFacebookDe(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1">
                  <Tooltip content="Instagram-Link (ID Link)" placement="top">
                    <InformationCircleIcon
                      strokeWidth={2}
                      className="h-10 w-10 text-[#5A5A5A]"
                    ></InformationCircleIcon>
                  </Tooltip>
                </div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      Instagram
                    </div>
                    <div className="col-span-9">
                      <Input
                        className="bg-white"
                        icon={<PencilSquareIcon />}
                        // @ts-ignore
                        value={instagramDe}
                        onChange={(e) => {
                          setInstagramDe(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1">
                  <Tooltip content="Weblink (ID Link)" placement="top">
                    <InformationCircleIcon
                      strokeWidth={2}
                      className="h-10 w-10 text-[#5A5A5A]"
                    ></InformationCircleIcon>
                  </Tooltip>
                </div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      Weblink
                    </div>
                    <div className="col-span-9">
                      <Input
                        className="bg-white"
                        icon={<PencilSquareIcon />}
                        // @ts-ignore
                        value={weblinkDe}
                        onChange={(e) => {
                          setWeblinkDe(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h1 className="text-[#5A5A5A] text-3xl font-normal mb-12">
                <br />
              </h1>
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1"></div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      Facebook
                    </div>
                    <div className="col-span-9">
                      <Input
                        className="bg-white"
                        icon={<PencilSquareIcon />}
                        // @ts-ignore
                        value={facebookEn}
                        onChange={(e) => {
                          setFacebookEn(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1"></div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      Instagram
                    </div>
                    <div className="col-span-9">
                      <Input
                        className="bg-white"
                        icon={<PencilSquareIcon />}
                        // @ts-ignore
                        value={instagramEn}
                        onChange={(e) => {
                          setInstagramEn(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1"></div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      Weblink
                    </div>
                    <div className="col-span-9">
                      <Input
                        className="bg-white"
                        icon={<PencilSquareIcon />}
                        // @ts-ignore
                        value={weblinkEn}
                        onChange={(e) => {
                          setWeblinkEn(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-b-4 border-[#5A5A5A] mx-5 mb-20">
          <div className="container m-auto mt-20 pb-12"></div>
        </div>
        <div className="container m-auto mt-12">
          <h1 className="text-[#5A5A5A] text-3xl font-normal mb-12">Bilder</h1>
          <div className="grid grid-cols-12 gap-4 items-center mb-6">
            <div className="col-span-1">
              <Tooltip
                content="Format: Querformat, max. Dateigröße 200kB"
                placement="top"
              >
                <InformationCircleIcon
                  strokeWidth={2}
                  className="h-10 w-10 text-[#5A5A5A]"
                ></InformationCircleIcon>
              </Tooltip>
            </div>
            <div className="col-span-11"></div>
          </div>
          <div className="grid grid-cols-12 gap-4 items-center mb-6">
            {oldImages.map((oldImage, index) => {
              return <Item key={index} item={oldImage} index={index} />;
            })}
          </div>
          <div className="grid grid-cols-12 gap-4 items-center mb-6">
            <div className="col-span-1"></div>
            <div className="col-span-11">
              {images.map((image, index) => {
                return !image.isHidden ? (
                  <>
                    <div className="grid grid-cols-12 mb-6" key={index}>
                      <div className="col-span-1 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                        Text
                      </div>
                      <div className="col-span-5">
                        {/* @ts-ignore */}
                        <Input
                          className="bg-white"
                          icon={<PencilSquareIcon />}
                          value={image.imageDescriptionDe}
                          onChange={(e) => {
                            handleInputDeChange(index, e);
                          }}
                        />
                      </div>
                      <div className="col-span-1 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                        Text
                      </div>
                      <div className="col-span-5">
                        {/* @ts-ignore */}
                        <Input
                          className="bg-white"
                          icon={<PencilSquareIcon />}
                          value={image.imageDescriptionEn}
                          onChange={(e) => {
                            handleInputEnChange(index, e);
                          }}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-12 mb-6">
                      <div className="col-span-1 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl"></div>
                      <div className="col-span-11">
                        <FileInput
                          className="text-[#5A5A5A]"
                          id="file-upload"
                          accept="image/*"
                          onChange={(e) => handleBilderChange(index, e)}
                        />
                        {image.previewUrl && (
                          <div className="relative mt-2 w-[max-content]">
                            {/* Thumbnail Preview */}
                            <img
                              src={image.previewUrl}
                              alt="Preview"
                              className="w-72 h-auto rounded-lg shadow-lg cursor-pointer transition-transform hover:scale-105"
                              onClick={() => modalToggle(index, true)} // Open modal on click
                            />

                            {/* Full-Size Modal */}
                            {image.isModalOpen && (
                              <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                                <img
                                  src={image.previewUrl}
                                  alt="Full Size Preview"
                                  className="max-w-full max-h-full rounded-lg shadow-xl"
                                />
                                <button
                                  className="absolute top-4 right-4 bg-white text-black p-2 rounded-full shadow-md"
                                  onClick={() => modalToggle(index, false)} // Close modal on click
                                >
                                  ✕
                                </button>
                              </div>
                            )}
                            <TrashIcon
                              strokeWidth={2}
                              className="h-6 w-6 text-[#5A5A5A]  cursor-pointer m-auto mt-2 transition"
                              onClick={() => removeImage(index)}
                            ></TrashIcon>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                ) : null;
              })}
            </div>
          </div>
          <div className="text-center mt-2">
            <div
              className="text-[#5A5A5A] text-xl text-center mt-2 inline-flex items-center"
              onClick={addNewImage}
            >
              Neues Bild hinzufügen
              <PlusCircleIcon
                strokeWidth={2}
                color="#5A5A5A"
                className="h-6 w-6"
              >
                {" "}
              </PlusCircleIcon>
            </div>
          </div>
        </div>
        <div className="border-b-4 border-[#5A5A5A] mx-5 mb-20">
          <div className="container m-auto mt-20 pb-12"></div>
        </div>
        <div className="container m-auto mt-12">
          <div className="grid lg:grid-cols-2 gap-6">
            <div>
              <h1 className="text-[#5A5A5A] text-3xl font-normal mb-12">
                Tipp
              </h1>
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1">
                  <Tooltip
                    content="Unbegrenzte Anzahl an Zeichen"
                    placement="top"
                  >
                    <InformationCircleIcon
                      strokeWidth={2}
                      className="h-10 w-10 text-[#5A5A5A]"
                    ></InformationCircleIcon>
                  </Tooltip>
                </div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      Beschreibung
                    </div>
                    <div className="col-span-9">
                      <Input
                        className="bg-white"
                        icon={<PencilSquareIcon />}
                        // @ts-ignore
                        value={tipDescDe}
                        onChange={(e) => {
                          setTipDescDe(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1">
                  <Tooltip content="Tip Link" placement="top">
                    <InformationCircleIcon
                      strokeWidth={2}
                      className="h-10 w-10 text-[#5A5A5A]"
                    ></InformationCircleIcon>
                  </Tooltip>
                </div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      Link
                    </div>
                    <div className="col-span-9">
                      <Input
                        className="bg-white"
                        icon={<PencilSquareIcon />}
                        // @ts-ignore
                        value={tipLinkDe}
                        onChange={(e) => {
                          setTipLinkDe(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1">
                  <Tooltip
                    content="Format: Querformat, max. Dateigröße 200kB"
                    placement="top"
                  >
                    <InformationCircleIcon
                      strokeWidth={2}
                      className="h-10 w-10 text-[#5A5A5A]"
                    ></InformationCircleIcon>
                  </Tooltip>
                </div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      Bild
                    </div>
                    {isTipDe ? (
                      <>
                        <div className="col-span-8">
                          <div className="relative my-2 w-[max-content]">
                            {/* Thumbnail Preview */}
                            <img
                              // @ts-ignore
                              src={tipDeUrl}
                              alt="Preview"
                              className="w-72 h-auto rounded-lg shadow-lg cursor-pointer transition-transform hover:scale-105"
                              onClick={() => modalToggleTipDeBasic(true)} // Open modal on click
                            />

                            {/* Full-Size Modal */}
                            {isModalOpenTipDeBasic && (
                              <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                                <img
                                  // @ts-ignore
                                  src={tipDeUrl}
                                  alt="Full Size Preview"
                                  className="max-w-full max-h-full rounded-lg shadow-xl"
                                />
                                <button
                                  className="absolute top-4 right-4 bg-white text-black p-2 rounded-full shadow-md"
                                  onClick={() => modalToggleTipDeBasic(false)} // Close modal on click
                                >
                                  ✕
                                </button>
                              </div>
                            )}
                          </div>
                          {/* @ts-ignore */}
                          <Input
                            className="bg-white"
                            icon={<PencilSquareIcon />}
                            value={
                              // @ts-ignore
                              "Aktuelle Datei : " + extractFileName(tipDeUrl)
                            }
                            readOnly
                          />
                        </div>
                        <div className="col-span-1 items-center flex">
                          <TrashIcon
                            strokeWidth={2}
                            className="h-6 w-6 text-[#5A5A5A]  cursor-pointer m-auto"
                            onClick={(e) => {
                              setTipDeUrl("");
                              setIsTipDe(false);
                            }}
                          ></TrashIcon>
                        </div>
                      </>
                    ) : (
                      <div className="col-span-9">
                        <FileInput
                          className="text-[#5A5A5A]"
                          accept="image/*"
                          id="file-upload"
                          onChange={handleTipDeChange}
                        />
                        {tipSrcDe && (
                          <div className="relative mt-2 w-[max-content]">
                            {/* Thumbnail Preview */}
                            <img
                              // @ts-ignore
                              src={tipSrcDe}
                              alt="Preview"
                              className="w-72 h-auto rounded-lg shadow-lg cursor-pointer transition-transform hover:scale-105"
                              onClick={() => modalToggleTip(true)} // Open modal on click
                            />

                            {/* Full-Size Modal */}
                            {isModalOpenTip && (
                              <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                                <img
                                  // @ts-ignore
                                  src={tipSrcDe}
                                  alt="Full Size Preview"
                                  className="max-w-full max-h-full rounded-lg shadow-xl"
                                />
                                <button
                                  className="absolute top-4 right-4 bg-white text-black p-2 rounded-full shadow-md"
                                  onClick={() => modalToggleTip(false)} // Close modal on click
                                >
                                  ✕
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h1 className="text-[#5A5A5A] text-3xl font-normal mb-12">
                <br />
              </h1>

              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1"></div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      Beschreibung
                    </div>
                    <div className="col-span-9">
                      <Input
                        className="bg-white"
                        icon={<PencilSquareIcon />}
                        // @ts-ignore
                        value={tipDescEn}
                        onChange={(e) => {
                          setTipDescEn(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1"></div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      Link
                    </div>
                    <div className="col-span-9">
                      <Input
                        className="bg-white"
                        icon={<PencilSquareIcon />}
                        // @ts-ignore
                        value={tipLinkEn}
                        onChange={(e) => {
                          setTipLinkEn(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1"></div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      <br />
                    </div>
                    <div className="col-span-9">
                      {/* <FileInput
                      className="text-[#5A5A5A]"
                      id="file-upload"
                      onChange={handleTipEnChange}
                    /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-b-4 border-[#5A5A5A] mx-5 mb-20">
          <div className="container m-auto mt-20 pb-12"></div>
        </div>
        <div className="container m-auto">
          <div className="grid gap-6">
            <div>
              <h1 className="text-[#5A5A5A] text-3xl font-normal mb-12">
                AR-Szene oder Panoramabild hinzufügen
              </h1>
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1">
                  <Tooltip
                    content="Auswählen, wenn AR-Szene vorhanden"
                    placement="top"
                  >
                    <InformationCircleIcon
                      strokeWidth={2}
                      className="h-10 w-10 text-[#5A5A5A]"
                    ></InformationCircleIcon>
                  </Tooltip>
                </div>
                <div className="col-span-2 flex items-center justify-center pr-2 text-[#5A5A5A] text-xl"></div>
                <div className="col-span-9">
                  {/* @ts-ignore */}
                  <Checkbox
                    checked={isAR}
                    onChange={(e) => setIsAR(e.target.checked)}
                    label={
                      // @ts-ignore
                      <Typography className="flex font-semibold text-[#5A5A5A]">
                        AR
                      </Typography>
                    }
                  />
                </div>
              </div>
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1">
                  <Tooltip
                    content="Auswählen, wenn die AR-Szene ein Panorama ist"
                    placement="top"
                  >
                    <InformationCircleIcon
                      strokeWidth={2}
                      className="h-10 w-10 text-[#5A5A5A]"
                    ></InformationCircleIcon>
                  </Tooltip>
                </div>
                <div className="col-span-2 flex items-center justify-center pr-2 text-[#5A5A5A] text-xl"></div>
                <div className="col-span-9">
                  {/* @ts-ignore */}
                  <Checkbox
                    checked={isPanorama}
                    onChange={(e) => setIsPanorama(e.target.checked)}
                    label={
                      // @ts-ignore
                      <Typography className="flex font-semibold text-[#5A5A5A]">
                        Panorama
                      </Typography>
                    }
                  />
                </div>
              </div>
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1">
                  <Tooltip
                    content="Zugewiesene Datei hochladen"
                    placement="top"
                  >
                    <InformationCircleIcon
                      strokeWidth={2}
                      className="h-10 w-10 text-[#5A5A5A]"
                    ></InformationCircleIcon>
                  </Tooltip>
                </div>
                <div className="col-span-2 flex items-center justify-center pr-2 text-[#5A5A5A] text-xl">
                  AR Standort
                </div>
                {isFileARImage ? (
                  <>
                    <div className="col-span-8">
                      <div className="relative my-2 w-[max-content]">
                        {/* Thumbnail Preview */}
                        <img
                          // @ts-ignore
                          src={fileARImageUrl}
                          alt="Preview"
                          className="w-72 h-auto rounded-lg shadow-lg cursor-pointer transition-transform hover:scale-105"
                          onClick={() => modalToggleARBasic(true)} // Open modal on click
                        />

                        {/* Full-Size Modal */}
                        {isModalOpenARBasic && (
                          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                            <img
                              // @ts-ignore
                              src={fileARImageUrl}
                              alt="Full Size Preview"
                              className="max-w-full max-h-full rounded-lg shadow-xl"
                            />
                            <button
                              className="absolute top-4 right-4 bg-white text-black p-2 rounded-full shadow-md"
                              onClick={() => modalToggleARBasic(false)} // Close modal on click
                            >
                              ✕
                            </button>
                          </div>
                        )}
                      </div>
                      {/* @ts-ignore */}
                      <Input
                        className="bg-white"
                        icon={<PencilSquareIcon />}
                        value={
                          // @ts-ignore
                          "Aktuelle Datei : " + extractFileName(fileARImageUrl)
                        }
                        readOnly
                      />
                    </div>
                    <div className="col-span-1 items-center flex">
                      <TrashIcon
                        strokeWidth={2}
                        className="h-6 w-6 text-[#5A5A5A]  cursor-pointer m-auto"
                        onClick={(e) => {
                          setFileARImageUrl("");
                          setIsFileARImage(false);
                        }}
                      ></TrashIcon>
                    </div>
                  </>
                ) : (
                  <div className="col-span-9">
                    <FileInput
                      className="text-[#5A5A5A]"
                      accept="image/*"
                      id="file-upload"
                      onChange={handleFileARImageChange}
                    />
                    {arImageSrcDe && (
                      <div className="relative mt-2 w-[max-content]">
                        {/* Thumbnail Preview */}
                        <img
                          // @ts-ignore
                          src={arImageSrcDe}
                          alt="Preview"
                          className="w-72 h-auto rounded-lg shadow-lg cursor-pointer transition-transform hover:scale-105"
                          onClick={() => modalToggleAR(true)} // Open modal on click
                        />

                        {/* Full-Size Modal */}
                        {isModalOpenAR && (
                          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                            <img
                              // @ts-ignore
                              src={arImageSrcDe}
                              alt="Full Size Preview"
                              className="max-w-full max-h-full rounded-lg shadow-xl"
                            />
                            <button
                              className="absolute top-4 right-4 bg-white text-black p-2 rounded-full shadow-md"
                              onClick={() => modalToggleAR(false)} // Close modal on click
                            >
                              ✕
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1">
                  <Tooltip
                    content="Zugewiesene Datei hochladen"
                    placement="top"
                  >
                    <InformationCircleIcon
                      strokeWidth={2}
                      className="h-10 w-10 text-[#5A5A5A]"
                    ></InformationCircleIcon>
                  </Tooltip>
                </div>
                <div className="col-span-2 flex items-center justify-center pr-2 text-[#5A5A5A] text-xl">
                  Panoramabild
                </div>
                {isFilePanoramaImage ? (
                  <>
                    <div className="col-span-8">
                      <div className="relative my-2 w-[max-content]">
                        {/* Thumbnail Preview */}
                        <img
                          // @ts-ignore
                          src={filePanoramaImageUrl}
                          alt="Preview"
                          className="w-72 h-auto rounded-lg shadow-lg cursor-pointer transition-transform hover:scale-105"
                          onClick={() => modalTogglePanoramaBasic(true)} // Open modal on click
                        />

                        {/* Full-Size Modal */}
                        {isModalOpenPanoramaBasic && (
                          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                            <img
                              // @ts-ignore
                              src={filePanoramaImageUrl}
                              alt="Full Size Preview"
                              className="max-w-full max-h-full rounded-lg shadow-xl"
                            />
                            <button
                              className="absolute top-4 right-4 bg-white text-black p-2 rounded-full shadow-md"
                              onClick={() => modalTogglePanoramaBasic(false)} // Close modal on click
                            >
                              ✕
                            </button>
                          </div>
                        )}
                      </div>
                      {/* @ts-ignore */}
                      <Input
                        className="bg-white"
                        icon={<PencilSquareIcon />}
                        value={
                          "Aktuelle Datei : " +
                          // @ts-ignore
                          extractFileName(filePanoramaImageUrl)
                        }
                        readOnly
                      />
                    </div>
                    <div className="col-span-1 items-center flex">
                      <TrashIcon
                        strokeWidth={2}
                        className="h-6 w-6 text-[#5A5A5A]  cursor-pointer m-auto"
                        onClick={(e) => {
                          setFilePanoramaImageUrl("");
                          setIsFilePanoramaImage(false);
                        }}
                      ></TrashIcon>
                    </div>
                  </>
                ) : (
                  <div className="col-span-9">
                    <FileInput
                      className="text-[#5A5A5A]"
                      accept="image/*"
                      id="file-upload"
                      onChange={handleFilePanoramaImageChange}
                    />
                    {panoramaImageSrcDe && (
                      <div className="relative mt-2 w-[max-content]">
                        {/* Thumbnail Preview */}
                        <img
                          // @ts-ignore
                          src={panoramaImageSrcDe}
                          alt="Preview"
                          className="w-72 h-auto rounded-lg shadow-lg cursor-pointer transition-transform hover:scale-105"
                          onClick={() => modalTogglePanorama(true)} // Open modal on click
                        />

                        {/* Full-Size Modal */}
                        {isModalOpenPanorama && (
                          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                            <img
                              // @ts-ignore
                              src={panoramaImageSrcDe}
                              alt="Full Size Preview"
                              className="max-w-full max-h-full rounded-lg shadow-xl"
                            />
                            <button
                              className="absolute top-4 right-4 bg-white text-black p-2 rounded-full shadow-md"
                              onClick={() => modalTogglePanorama(false)} // Close modal on click
                            >
                              ✕
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="border-b-4 border-[#5A5A5A] mx-5 mb-20">
          <div className="container m-auto mt-20 pb-12"></div>
        </div>
        <div className="container m-auto mb-40">
          <h1 className="text-[#5A5A5A] text-3xl font-normal mb-12">
            Weiteres
          </h1>
          <div className="grid grid-cols-12 gap-4 items-center mb-6">
            <div className="col-span-1">
              <Tooltip
                content="Soll der POI in der App in der Übersicht ausgeblendet werden?"
                placement="top"
              >
                <InformationCircleIcon
                  strokeWidth={2}
                  className="h-10 w-10 text-[#5A5A5A]"
                ></InformationCircleIcon>
              </Tooltip>
            </div>
            <div className="col-span-2"></div>
            <div className="col-span-9">
              {/* @ts-ignore */}
              <Checkbox
                checked={willbeHidden}
                onChange={(e) => setWillbeHidden(e.target.checked)}
                label={
                  // @ts-ignore
                  <Typography className="flex font-semibold text-[#5A5A5A]">
                    Ausblenden in POI Übersicht
                  </Typography>
                }
              />
            </div>
          </div>
          <div className="grid grid-cols-12 gap-4 items-center mb-20">
            <div className="col-span-1">
              <Tooltip
                content="GPS-Koordinaten Schreibweise: Eingabe von Längen- und Breitengrad nach Google, mit Komma separiert (z.B. „53.5430895,8.5057031“)"
                placement="top"
              >
                <InformationCircleIcon
                  strokeWidth={2}
                  className="h-10 w-10 text-[#5A5A5A]"
                ></InformationCircleIcon>
              </Tooltip>
            </div>
            <div className="col-span-2 text-[#5A5A5A] text-xl">GPS</div>
            <div className="col-span-9">
              <Input
                className="bg-white"
                icon={<PencilSquareIcon />}
                // @ts-ignore
                value={GPS}
                onChange={(e) => {
                  setGPS(e.target.value);
                  setGPSError(false);
                  setError(false);
                }}
              />
              {GPSError ? (
                <p className="text-red-800">Please fill out this field.</p>
              ) : null}
            </div>
          </div>
          <div className="grid lg:grid-cols-6">
            <div className="col-span-4">
              {/* @ts-ignore */}
              <Checkbox
                checked={published}
                onChange={(e) => setPublished(e.target.checked)}
                label={
                  // @ts-ignore
                  <Typography className="flex font-semibold text-[#5A5A5A]">
                    Veröffentlichen
                  </Typography>
                }
              />
            </div>
            <div>
              <Link to="/poi">
                {/* @ts-ignore */}
                <Button
                  variant="outlined"
                  className="text-[#5A5A5A] px-10 bg-white border-none"
                >
                  Abbrechen
                </Button>
              </Link>
            </div>
            <div>
              {/* @ts-ignore */}
              <Button
                variant="outlined"
                className="text-[#5A5A5A] px-10 bg-white border-none hover:text-red-800"
                onClick={onSubmit}
              >
                Speichern
              </Button>
              {error ? (
                <div className="text-red-800 mt-2">
                  Bitte fülle alle Pflichtfelder aus.
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </>
    </DndProvider>
  );
};

export default EditPoiPage;
