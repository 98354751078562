import React, {
  ChangeEvent,
  ChangeEventHandler,
  useCallback,
  useMemo,
} from "react";

import { NavbarWithMegaMenu } from "../components/navbar";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  TrashIcon,
  PencilSquareIcon,
  PlusCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import {
  Button,
  Checkbox,
  Typography,
  Tooltip,
  Textarea,
  Select,
  Option,
  Spinner,
  Input,
} from "@material-tailwind/react";
import { FileInput } from "flowbite-react";
import DeuFlagImg from "../../images/flags/deuflage.png";
import AMFlagImg from "../../images/flags/amflag.png";
import { useState, useEffect, useRef } from "react";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  where,
  query,
  DocumentReference,
  DocumentData,
} from "firebase/firestore";
import { db } from "../../config/firebase-config";
import { useDrag, useDrop } from "react-dnd";
import { IPoi } from "../../features/firebase/dataStructure/Poi";
import { IPois, ITour } from "../../features/firebase/dataStructure/Tour";
import { Helmet } from "react-helmet";
import {
  handleAudioInputFileChange,
  handleImageInputFileChange,
  handleImagesInputFileChange,
  IAudio,
  IImages,
  removeImageFromState,
  resetAudio,
  updateImageIsModalOpen,
} from "src/utils/form/updateFieldStateUtil";
import {
  gsToHttps,
  httpsToGs,
  uploadFile,
} from "src/utils/firebase/firebaseUtil";

const EditTourPage = () => {
  const [error, setError] = useState<boolean>(false);
  const moveItem = (dragIndex: number, hoverIndex: number) => {
    const draggedItem = selectedPois[dragIndex];
    const newSortedItems = [...selectedPois];
    newSortedItems.splice(dragIndex, 1);
    newSortedItems.splice(hoverIndex, 0, draggedItem);
    setSelectedPois(newSortedItems);
  };
  const moveBilderItem = (dragIndex: number, hoverIndex: number) => {
    const draggedItem = oldImages[dragIndex];
    const newSortedItems = [...oldImages];
    newSortedItems.splice(dragIndex, 1);
    newSortedItems.splice(hoverIndex, 0, draggedItem);
    setOldImages(newSortedItems);
  };
  const BilderItem = ({ item, index }) => {
    const [{ isDragging }, drag] = useDrag({
      type: ItemTypes.CARD,
      item: { type: ItemTypes.CARD, index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const [, drop] = useDrop({
      accept: ItemTypes.CARD,
      hover: (item: any) => {
        const dragIndex = item.index;
        const hoverIndex = index;

        if (dragIndex === hoverIndex) {
          return;
        }

        moveBilderItem(dragIndex, hoverIndex);
        item.index = hoverIndex;
      },
    });

    // const opacity = isDragging ? 0.5 : 1;

    return (
      <div
        ref={(node) => drag(drop(node))}
        className={`col-span-3 ${isDragging ? "opacity-50" : ""}`}
        style={{ cursor: "move" }}
      >
        <div>
          <img
            src={item.imageUrl}
            className="cursor-pointer"
            onClick={() => modalToggleOld(index, true)}
            alt=""
          />
          {item.isModalOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <img
                src={item.imageUrl}
                alt="Full Size Preview"
                className="max-w-full max-h-full rounded-lg shadow-xl"
              />
              <button
                className="absolute top-4 right-4 bg-white text-black p-2 rounded-full shadow-md"
                onClick={() => modalToggleOld(index, false)}
              >
                ✕
              </button>
            </div>
          )}
        </div>
        <div className="mt-1">
          <TrashIcon
            strokeWidth={2}
            className="h-6 w-6 text-[#5A5A5A]  cursor-pointer m-auto"
            onClick={(e) => {
              removeOldImage(index);
            }}
          ></TrashIcon>
        </div>
      </div>
    );
  };
  const Item = ({ item, index }: { item: IPoi; index: number }) => {
    const [{ isDragging }, drag] = useDrag({
      type: ItemTypes.CARD,
      item: { type: ItemTypes.CARD, index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const [, drop] = useDrop({
      accept: ItemTypes.CARD,
      hover: (item: any) => {
        const dragIndex = item.index;
        const hoverIndex = index;

        if (dragIndex === hoverIndex) {
          return;
        }

        moveItem(dragIndex, hoverIndex);
        item.index = hoverIndex;
      },
    });

    // const opacity = isDragging ? 0.5 : 1;

    return (
      <div
        ref={(node) => drag(drop(node))}
        className={`grid grid-cols-12 gap-4 items-center mb-6 ${
          isDragging ? "opacity-50" : ""
        }`}
        style={{ cursor: "move" }}
      >
        <div className="col-span-10">
          {/* @ts-ignore */}
          <Input
            className="bg-white"
            icon={<PencilSquareIcon />}
            value={item?.languages?.de?.name ?? ""}
            readOnly
          />
        </div>
        <div className="col-span-1">
          <Link to={"/poi/" + item.id}>
            <PencilSquareIcon
              strokeWidth={2}
              className="h-6 w-6 text-[#5A5A5A]  cursor-pointer m-auto"
            ></PencilSquareIcon>
          </Link>
        </div>
        <div className="col-span-1">
          <TrashIcon
            onClick={() => removePoi(item?.id ?? "")}
            strokeWidth={2}
            className="h-6 w-6 text-[#5A5A5A]  cursor-pointer m-auto"
          ></TrashIcon>
        </div>
      </div>
    );
  };
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [tourID, setTourID] = useState<string>("");

  const [pois, setPois] = useState<IPoi[]>([]);
  const [selectedPois, setSelectedPois] = useState<IPoi[]>([]);
  const [selectedPoi, setSelectedPoi] = useState("");
  const [addCompleted, setAddCompleted] = useState(true);
  const [canAddPoi, setCanAddPoi] = useState(false);
  const handleSelectChange = (value) => setSelectedPoi(value);

  const addNewPoi = () => {
    if (addCompleted) {
      setCanAddPoi(true);
      setAddCompleted(false);
    }
  };
  const plusNewPoi = () => {
    if (selectedPoi === "") return;
    const selectedPoiTemp = pois.find((poi) => poi.id === selectedPoi); // Find selected POI from the list
    if (selectedPoiTemp) {
      setSelectedPois([...selectedPois, selectedPoiTemp]); // Add to selectedPois array
      // if (selectedPoiTemp && !selectedPois.includes(selectedPoiTemp)) {
      // }
    }

    setCanAddPoi(false);
    setAddCompleted(true);
    setSelectedPoi("");
  };
  const cancelNewPoi = () => {
    setSelectedPoi("");
    setCanAddPoi(false);
    setAddCompleted(true);
  };
  const removePoi = (poiId: string) => {
    setSelectedPois(selectedPois.filter((poi) => poi.id !== poiId));
  };

  const [nameDe, setNameDe] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [descEn, setDescEn] = useState("");
  const [descDe, setDescDe] = useState("");
  const [published, setPublished] = useState(false);
  const [firstTextDe, setFirstTextDe] = useState("");
  const [firstTextEn, setFirstTextEn] = useState("");
  const [priority, setPriority] = useState(0);
  const [nameDeError, setNameDeError] = useState(false);
  const [nameEnError, setNameEnError] = useState(false);
  const [descDeError, setDescDeError] = useState(false);
  const [descEnError, setDescEnError] = useState(false);

  const [videoNameDe, setVideoNameDe] = useState("");
  const [videoNameEn, setVideoNameEn] = useState("");
  const [videoLinkDe, setVideoLinkDe] = useState("");
  const [videoLinkEn, setVideoLinkEn] = useState("");
  const [duration, setDuration] = useState("");
  const [durationTime, setDurationTime] = useState("");

  const [fileDe, setFileDe] = useState<File | null>(null);
  const [fileEn, setFileEn] = useState<File | null>(null);
  const [DescAudioDe, setDescAudioDe] = useState<File | null>(null);
  const [DescAudioEn, setDescAudioEn] = useState<File | null>(null);
  const [storyDe, setStoryDe] = useState<File | null>(null);
  const [storyEn, setStoryEn] = useState<File | null>(null);
  const [thumbFile, setThumbFile] = useState<File | null>(null);
  const [tourBGImageFile, setTourBGImageFile] = useState<File | null>(null);
  const [images, setImages] = useState<IImages[]>([]);
  const [oldImages, setOldImages] = useState<
    {
      imageUrl: string;
      isModalOpen: boolean;
    }[]
  >([]);

  const [descAudioFileDeUrl, setDescAudioFileDeUrl] = useState("");
  const [isDescAudioFileDe, setIsDescAudioFileDe] = useState(false);
  const [descAudioFileEnUrl, setDescAudioFileEnUrl] = useState("");
  const [isDescAudioFileEn, setIsDescAudioFileEn] = useState(false);
  const [firstAudioFileDeUrl, setFirstAudioFileDeUrl] = useState("");
  const [isFirstAudioFileDe, setIsFirstAudioFileDe] = useState(false);
  const [firstAudioFileEnUrl, setFirstAudioFileEnUrl] = useState("");
  const [isFirstAudioFileEn, setIsFirstAudioFileEn] = useState(false);
  const [storyFileDeUrl, setStoryFileDeUrl] = useState("");
  const [isStoryFileDe, setIsStoryFileDe] = useState(false);
  const [storyFileEnUrl, setStoryFileEnUrl] = useState("");
  const [isStoryFileEn, setIsStoryFileEn] = useState(false);
  const [thumbFileUrl, setThumbFileUrl] = useState("");
  const [isThumbFile, setIsThumbFile] = useState(false);
  const [tourBGFileUrl, setTourBGFileUrl] = useState("");
  const [isTourBGFile, setIsTourBGFile] = useState(false);

  const audioRefDe = useRef<HTMLAudioElement>(null);
  const audioRefEn = useRef<HTMLAudioElement>(null);
  const storyRefDe = useRef<HTMLAudioElement>(null);
  const storyRefEn = useRef<HTMLAudioElement>(null);
  const descAudioRefDe = useRef<HTMLAudioElement>(null);
  const descAudioRefEn = useRef<HTMLAudioElement>(null);
  const [audioSrcDe, setAudioSrcDe] = useState<IAudio>(null);
  const [audioSrcEn, setAudioSrcEn] = useState<IAudio>(null);
  const [storySrcDe, setStorySrcDe] = useState<IAudio>(null);
  const [storySrcEn, setStorySrcEn] = useState<IAudio>(null);
  const [descAudioSrcDe, setDescAudioSrcDe] = useState<IAudio>(null);
  const [descAudioSrcEn, setDescAudioSrcEn] = useState<IAudio>(null);
  const [thumbSrcDe, setThumbSrcDe] = useState<IAudio>(null);
  const [isModalOpenThumb, setIsModalOpenThumb] = useState<boolean>(false);
  const [bgImageSrcDe, setBgImageSrcDe] = useState<IAudio>(null);
  const [isModalOpenBG, setIsModalOpenBG] = useState<boolean>(false);

  const [isModalOpenThumbBasic, setIsModalOpenThumbBasic] = useState(false);
  const [isModalOpenBGBasic, setIsModalOpenBGBasic] = useState(false);
  const modalToggleThumbBasic = (isOpen) => setIsModalOpenThumbBasic(isOpen);
  const modalToggleBGBasic = (isOpen) => setIsModalOpenBGBasic(isOpen);

  const removeOldImage = (index: number) =>
    setOldImages((prevImages) => prevImages.filter((_, i) => i !== index));

  const modalToggleOld = (index: number, isOpen: boolean) => {
    const newImages = [...oldImages]; // Create a copy of the state array
    newImages[index].isModalOpen = isOpen;
    setOldImages(newImages);
  };
  const initData = useCallback(() => {
    const fetchPois = async () => {
      try {
        const q = query(
          collection(db, "poi"),
          where("hideInOverview", "==", false) // Add the filter
        );
        const querySnapshot = await getDocs(q);

        const fetchedData: IPoi[] = querySnapshot.docs.map((doc) => ({
          id: doc.id, // Include document ID if needed
          ...doc.data(), // Spread document data
        }));
        console.log(fetchedData);
        setPois(fetchedData);
        // Call fetchData after pois are set
        await fetchData(fetchedData);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    const fetchData = async (fetchedPois: IPoi[]) => {
      const path = window.location.pathname;
      const id = path.split("/").pop(); // Assuming faq_id is at the end of the URL

      if (!!!id) return;
      setTourID(id);
      const docRef = doc(db, "tour", id);
      const docSnap = await getDoc(docRef);
      if (!docSnap.exists()) {
        console.log("No such document!");
        return;
      }
      const data: ITour = docSnap.data(); // Extracts the data
      const poiProperties = data.pois ?? [];
      const poiIds: string[] = [];
      await Promise.all(
        poiProperties.map(async (item) => {
          if (typeof item?.poi === "string") {
            const data = item?.poi?.split("/") ?? [];
            const id = data?.pop() ?? "";
            poiIds.push(id);
          } else if (item?.poi instanceof DocumentReference) {
            // Fix bug: the POI could be a string or reference type.
            try {
              const poiDoc: DocumentReference = item?.poi as any;
              if (poiDoc?.id) poiIds.push(poiDoc.id);
              // const data = await getDoc(poiDoc);
              // const pos = data.data() as IPoi;
            } catch (e) {
              console.error(e);
            }
          }
        })
      );
      // const poiIds = poiProperties.map((item) => {
      //   const data = item?.poi?.split("/") ?? [];
      //   return data?.pop() ?? "";
      // });
      type IPriorityMap = { [key: string]: any };
      const priorityMap = await poiProperties.reduce(async (_acc, item) => {
        const acc = await _acc;
        if (typeof item?.poi === "string") {
          const id = item?.poi?.split("/")?.pop();
          if (typeof id === "string") acc[id] = item.priority;
        } else if (item?.poi instanceof DocumentReference) {
          // Fix bug: the POI could be a string or reference type.
          try {
            const poiDoc: DocumentReference = item?.poi as any;
            if (poiDoc?.id && typeof poiDoc?.id === "string") {
              acc[poiDoc?.id] = item.priority;
            }
          } catch (e) {
            console.error(e);
          }
        }
        return acc;
      }, {} as IPriorityMap);

      // const priorityMap: IPois = poiProperties.reduce((acc, item) => {
      //   if (typeof item?.poi === "string") {
      //     const id = item?.poi?.split("/")?.pop();
      //     if (id) acc[id] = item.priority;
      //   }
      //   return acc;
      // }, {});
      const newPois: IPoi[] = fetchedPois
        .filter((poi) => poiIds.includes(poi.id ?? ""))
        .sort((a, b) => priorityMap[a.id ?? ""] - priorityMap[b.id ?? ""]);

      setSelectedPois(newPois);
      const newOldImages = await Promise.all(
        data?.images?.map(async (url) => {
          const result = await {
            imageUrl: await gsToHttps(url),
            isModalOpen: false,
          };
          return result;
        }) ?? []
      );
      // Set other data as before
      setOldImages(newOldImages);
      setNameDe(data?.languages?.de?.name ?? "");
      setNameEn(data?.languages?.en?.name ?? "");
      setDescDe(data?.languages?.de?.description ?? "");
      setDescEn(data?.languages?.en?.description ?? "");
      setPublished(data?.published ?? false);
      setFirstTextDe(data?.languages?.de?.firstText ?? "");
      setFirstTextEn(data?.languages?.en?.firstText ?? "");
      setVideoNameDe(data?.languages?.de?.VideoName ?? "");
      setVideoNameEn(data?.languages?.en?.VideoName ?? "");
      setVideoLinkDe(data?.languages?.de?.videoLink ?? "");
      setVideoLinkEn(data?.languages?.en?.videoLink ?? "");
      setDuration(data?.duration ?? "");
      setDurationTime(data?.durationTime ?? "");
      setPriority(data?.priority ?? 0);
      if (data?.languages?.de?.DescriptionAudio) {
        setDescAudioFileDeUrl(
          await gsToHttps(data.languages.de.DescriptionAudio)
        );
        setIsDescAudioFileDe(true);
      }
      if (data?.languages?.en.DescriptionAudio) {
        setDescAudioFileEnUrl(
          await gsToHttps(data.languages.en.DescriptionAudio)
        );
        setIsDescAudioFileEn(true);
      }
      if (data?.languages?.de.firstAudio) {
        setFirstAudioFileDeUrl(await gsToHttps(data.languages.de.firstAudio));
        setIsFirstAudioFileDe(true);
      }
      if (data?.languages?.en.firstAudio) {
        setFirstAudioFileEnUrl(await gsToHttps(data.languages.en.firstAudio));
        setIsFirstAudioFileEn(true);
      }
      if (data?.languages?.de.audiostory) {
        setStoryFileDeUrl(await gsToHttps(data.languages.de.audiostory));
        setIsStoryFileDe(true);
      }
      if (data?.languages?.en.audiostory) {
        setStoryFileEnUrl(await gsToHttps(data.languages.en.audiostory));
        setIsStoryFileEn(true);
      }
      if (data.ThumbnailImage) {
        setThumbFileUrl(await gsToHttps(data.ThumbnailImage));
        setIsThumbFile(true);
      }
      if (data.TourBGImage) {
        setTourBGFileUrl(await gsToHttps(data.TourBGImage));
        setIsTourBGFile(true);
      }
    };

    fetchPois();
  }, []);

  useEffect(() => {
    initData();
  }, []);

  const extractFileName = (url: string) => {
    const urlAndParams = url.split("?");
    if (urlAndParams.length === 0) return "";
    const path = urlAndParams[0]; // Removes query parameters
    const fileName = path.substring(path.lastIndexOf("/") + 1);
    return decodeURIComponent(fileName); // Decodes the URL-encoded characters
  };
  const handleFileDeChange: ChangeEventHandler<HTMLInputElement> = (event) =>
    handleAudioInputFileChange(event, setFileDe, setAudioSrcDe, resetAudioDe);
  const handleFileEnChange: ChangeEventHandler<HTMLInputElement> = (event) =>
    handleAudioInputFileChange(event, setFileEn, setAudioSrcEn, resetAudioEn);
  const resetAudioDe = () => resetAudio(audioRefDe);
  const resetAudioEn = () => resetAudio(audioRefEn);
  const handleBilderChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => handleImagesInputFileChange(event, index, setImages);
  const removeImage = (index: number) =>
    removeImageFromState(index, images, setImages);

  const modalToggle = (index: number, isOpen: boolean) =>
    updateImageIsModalOpen(images, index, isOpen, setImages);
  const handleDescAudioDeChange: ChangeEventHandler<HTMLInputElement> = (
    event
  ) =>
    handleAudioInputFileChange(
      event,
      setDescAudioDe,
      setDescAudioSrcDe,
      resetDescAudioDe
    );
  const handleDescAudioEnChange = (event) =>
    handleAudioInputFileChange(
      event,
      setDescAudioEn,
      setDescAudioSrcEn,
      resetDescAudioEn
    );

  const resetDescAudioDe = () => resetAudio(descAudioRefDe);
  const resetDescAudioEn = () => resetAudio(descAudioRefEn);
  const handleThumbChange: ChangeEventHandler<HTMLInputElement> = (event) =>
    handleImageInputFileChange(event, setThumbFile, setThumbSrcDe);

  const handleBGImageChange: ChangeEventHandler<HTMLInputElement> = (event) =>
    handleImageInputFileChange(event, setTourBGImageFile, setBgImageSrcDe);
  const modalToggleThumb = (isOpen: boolean) => setIsModalOpenThumb(isOpen);
  const modalToggleBG = (isOpen: boolean) => setIsModalOpenBG(isOpen);
  const handleAudioStoryDeChange: ChangeEventHandler<HTMLInputElement> = (
    event
  ) =>
    handleAudioInputFileChange(event, setStoryDe, setStorySrcDe, resetStoryDe);

  const handleAudioStoryEnChange: ChangeEventHandler<HTMLInputElement> = (
    event
  ) =>
    handleAudioInputFileChange(event, setStoryEn, setStorySrcEn, resetStoryEn);

  const resetStoryDe = () => resetAudio(storyRefDe);
  const resetStoryEn = () => resetAudio(storyRefEn);

  const hasEmptyImage = useMemo(() => {
    if (images.length === 0) return false;
    return !!images.find((image) => image.imageFile === null);
  }, [images]);
  // Function to add a new item to the linksDe array
  const addNewImage = () => {
    // if (hasEmptyImage) return;
    setImages([
      ...images,
      {
        imageFile: null,
        imageUrl: "",
        previewUrl: null,
        isModalOpen: false,
        isHidden: false,
      },
    ]); // Add a new empty string to the array
  };
  // const handleInputDeChange = (index, event) => {
  //   const newImages = [...images]; // Create a copy of the state array
  //   newImages[index].imageDescriptionDe = event.target.value; // Update the specific index with the new value
  //   setImages(newImages); // Update the state
  // };
  // const handleInputEnChange = (index, event) => {
  //   const newImages = [...images]; // Create a copy of the state array
  //   newImages[index].imageDescriptionEn = event.target.value; // Update the specific index with the new value
  //   setImages(newImages); // Update the state
  // };

  const onSubmit = async () => {
    if (nameDe === "" || nameEn === "" || descDe === "" || descEn === "") {
      setError(true);
    }
    if (nameDe === "") return setNameDeError(true);
    if (nameEn === "") return setNameEnError(true);
    if (descDe === "") return setDescDeError(true);
    if (descEn === "") return setDescEnError(true);

    setLoading(true);

    const filteredImages = images.filter(
      (image) => image.imageFile !== null && image.isHidden == false
    );

    try {
      // Parallel uploading of all images and files using Promise.all
      const imageUploadPromises = filteredImages.map(async (image) => {
        image.imageUrl = await uploadFile(image.imageFile);
        return image.imageUrl;
      });

      const [
        audiostoryUrl = storyFileDeUrl,
        audiostoryUrlEn = storyFileEnUrl,
        firstAudioUrl = firstAudioFileDeUrl,
        firstAudioUrlEn = firstAudioFileEnUrl,
        descAudioDeUrl = descAudioFileDeUrl,
        descAudioEnUrl = descAudioFileEnUrl,
        ThumbnailImageUrl = thumbFileUrl,
        TourBGImageUrl = tourBGFileUrl,
      ] = await Promise.all([
        storyDe && uploadFile(storyDe),
        storyEn && uploadFile(storyEn),
        fileDe && uploadFile(fileDe),
        fileEn && uploadFile(fileEn),
        DescAudioDe && uploadFile(DescAudioDe),
        DescAudioEn && uploadFile(DescAudioEn),
        thumbFile && uploadFile(thumbFile),
        tourBGImageFile && uploadFile(tourBGImageFile),
        ...imageUploadPromises,
      ]);

      const imageUrls: string[] = [];
      filteredImages.forEach((image) => {
        if (image.imageUrl) imageUrls.push(image.imageUrl);
      });
      const originalImageUrls = oldImages.map((item) => item.imageUrl);
      const newImages = [...originalImageUrls, ...imageUrls].map((image) =>
        httpsToGs(image)
      );
      const formattedArray = (
        await Promise.all(
          selectedPois.map(async (poi, index) => {
            if (!poi.id) return;
            const docRef = doc(db, "poi", poi.id);
            return {
              // poi: `/poi/${poi.id}`,
              poi: docRef,
              priority: index + 1,
            };
          })
        )
      ).filter((poi) => poi) as {
        poi: DocumentReference<DocumentData, DocumentData>;
        priority: number;
      }[];
      // const formattedArray = selectedPois.map((poi, index) => ({
      //   poi: `/poi/${poi.id}`,
      //   priority: index + 1,
      // }));

      const tourData: ITour = {
        duration,
        durationTime,
        images: newImages,
        TourBGImage: TourBGImageUrl ?? httpsToGs(tourBGFileUrl),
        languages: {
          de: {
            firstText: firstTextDe,
            DescriptionAudio: descAudioDeUrl ?? httpsToGs(descAudioFileDeUrl),
            firstAudio: firstAudioUrl ?? httpsToGs(firstAudioFileDeUrl),
            VideoName: videoNameDe,
            name: nameDe,
            description: descDe,
            videoLink: videoLinkDe,
            audiostory: audiostoryUrl ?? httpsToGs(storyFileDeUrl),
          },
          en: {
            firstText: firstTextEn,
            DescriptionAudio: descAudioEnUrl ?? httpsToGs(descAudioFileEnUrl),
            firstAudio: firstAudioUrlEn ?? httpsToGs(firstAudioFileEnUrl),
            VideoName: videoNameEn,
            name: nameEn,
            description: descEn,
            videoLink: videoLinkEn,
            audiostory: audiostoryUrlEn ?? httpsToGs(storyFileEnUrl),
          },
        },
        ThumbnailImage: ThumbnailImageUrl ?? httpsToGs(thumbFileUrl),
        published,
        priority: priority,
        pois: formattedArray,
      };
      const docRef = doc(db, "tour", tourID);
      await updateDoc(docRef, tourData as any);
      setLoading(false);
      navigate("/tour");
    } catch (error) {
      console.error("Error during submission:", error);
      setLoading(false);
    }
  };
  return (
    <DndProvider backend={HTML5Backend}>
      <>
        {/* @ts-ignore */}
        <Helmet>
          <title>Neuen Tour erstellen | BremenGo</title>
          <meta name="description" content="BremenGo Home Screen" />
        </Helmet>
        <NavbarWithMegaMenu />
        {loading && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-75">
            {/* @ts-ignore */}
            <Spinner size="3xl" color="white" />
          </div>
        )}
        <div className="border-b-4 border-[#5A5A5A] mx-5">
          <div className="container m-auto mt-20 pb-12">
            <h1 className="text-[#5A5A5A] text-3xl font-medium">
              Neuen Tour erstellen
            </h1>
          </div>
        </div>
        <div className="container m-auto mt-12">
          <div className="grid lg:grid-cols-2 gap-6">
            <div>
              <div className="text-center m-auto mb-16">
                <img src={DeuFlagImg} className="m-auto" />
              </div>
              <h1 className="text-[#5A5A5A] text-3xl font-normal mb-12">
                Tour Vorschau
              </h1>

              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1">
                  <Tooltip content="Max. 18 Zeichen" placement="top">
                    <InformationCircleIcon
                      strokeWidth={2}
                      className="h-10 w-10 text-[#5A5A5A]"
                    ></InformationCircleIcon>
                  </Tooltip>
                </div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      Name
                    </div>
                    <div className="col-span-9">
                      {/* @ts-ignore */}
                      <Input
                        className="bg-white"
                        icon={<PencilSquareIcon />}
                        value={nameDe}
                        onChange={(e) => {
                          setNameDe(e.target.value);
                          setNameDeError(false);
                          setError(false);
                        }}
                      />
                      {nameDeError ? (
                        <p className="text-red-800">
                          Please fill out this field.
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1">
                  <Tooltip
                    content="Max. 220 Zeichen inkl. Leerzeichen"
                    placement="top"
                  >
                    <InformationCircleIcon
                      strokeWidth={2}
                      className="h-10 w-10 text-[#5A5A5A]"
                    ></InformationCircleIcon>
                  </Tooltip>
                </div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      Kurztext
                    </div>
                    <div className="col-span-9">
                      <Textarea
                        className="bg-white"
                        // @ts-ignore
                        icon={<PencilSquareIcon />}
                        value={descDe}
                        onChange={(e) => {
                          setDescDeError(false);
                          setError(false);
                          setDescDe(e.target.value);
                        }}
                      />
                      {descDeError ? (
                        <p className="text-red-800">
                          Please fill out this field.
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1">
                  <Tooltip
                    content="Vorlesetext für Barrierefreiheit, Audioformat: mp3, max. Dateigröße: 200kB"
                    placement="top"
                  >
                    <InformationCircleIcon
                      strokeWidth={2}
                      className="h-10 w-10 text-[#5A5A5A]"
                    ></InformationCircleIcon>
                  </Tooltip>
                </div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      Audio(Kurztext)
                    </div>
                    {isDescAudioFileDe ? (
                      <>
                        <div className="col-span-8">
                          {/* @ts-ignore */}
                          <Input
                            className="bg-white"
                            icon={<PencilSquareIcon />}
                            value={
                              "Aktuelle Datei : " +
                              extractFileName(descAudioFileDeUrl)
                            }
                            readOnly
                          />
                          <audio controls className="mt-2">
                            <source src={descAudioFileDeUrl} type="audio/mp3" />
                            Your browser does not support the audio tag.
                          </audio>
                        </div>
                        <div className="col-span-1 items-center flex">
                          <TrashIcon
                            strokeWidth={2}
                            className="h-6 w-6 text-[#5A5A5A]  cursor-pointer m-auto"
                            onClick={(e) => {
                              setDescAudioFileDeUrl("");
                              setIsDescAudioFileDe(false);
                            }}
                          ></TrashIcon>
                        </div>
                      </>
                    ) : (
                      <div className="col-span-9">
                        <FileInput
                          className="text-[#5A5A5A]"
                          accept="audio/*"
                          id="file-upload"
                          onChange={handleDescAudioDeChange}
                        />
                        {descAudioSrcDe && (
                          <audio ref={descAudioRefDe} controls className="mt-2">
                            <source
                              src={descAudioSrcDe as string}
                              type="audio/mp3"
                            />
                            Your browser does not support the audio tag.
                          </audio>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <h1 className="text-[#5A5A5A] text-3xl font-normal mb-12 mt-12">
                Tour Detailseite
              </h1>

              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1">
                  <Tooltip
                    content="Unbegrenzte Anzahl an Zeichen"
                    placement="top"
                  >
                    <InformationCircleIcon
                      strokeWidth={2}
                      className="h-10 w-10 text-[#5A5A5A]"
                    ></InformationCircleIcon>
                  </Tooltip>
                </div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      Text
                    </div>
                    <div className="col-span-9">
                      {/* @ts-ignore */}
                      <Input
                        className="bg-white"
                        icon={<PencilSquareIcon />}
                        value={firstTextDe}
                        onChange={(e) => {
                          setFirstTextDe(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1">
                  <Tooltip
                    content="Vorlesetext für Barrierefreiheit, Audioformat: mp3, max. Dateigröße: 200kB"
                    placement="top"
                  >
                    <InformationCircleIcon
                      strokeWidth={2}
                      className="h-10 w-10 text-[#5A5A5A]"
                    ></InformationCircleIcon>
                  </Tooltip>
                </div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      Audio
                    </div>
                    {isFirstAudioFileDe ? (
                      <>
                        <div className="col-span-8">
                          {/* @ts-ignore */}
                          <Input
                            className="bg-white"
                            icon={<PencilSquareIcon />}
                            value={
                              "Aktuelle Datei : " +
                              extractFileName(firstAudioFileDeUrl)
                            }
                            readOnly
                          />
                          <audio controls className="mt-2">
                            <source
                              src={firstAudioFileDeUrl}
                              type="audio/mp3"
                            />
                            Your browser does not support the audio tag.
                          </audio>
                        </div>
                        <div className="col-span-1 items-center flex">
                          <TrashIcon
                            strokeWidth={2}
                            className="h-6 w-6 text-[#5A5A5A]  cursor-pointer m-auto"
                            onClick={(e) => {
                              setFirstAudioFileDeUrl("");
                              setIsFirstAudioFileDe(false);
                            }}
                          ></TrashIcon>
                        </div>
                      </>
                    ) : (
                      <div className="col-span-9">
                        <FileInput
                          className="text-[#5A5A5A]"
                          accept="audio/*"
                          id="file-upload"
                          onChange={handleFileDeChange}
                        />
                        {audioSrcDe && (
                          <audio ref={audioRefDe} controls className="mt-2">
                            <source
                              src={audioSrcDe as string}
                              type="audio/mp3"
                            />
                            Your browser does not support the audio tag.
                          </audio>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1">
                  <Tooltip
                    content="Audiostory, Audioformat: mp3, max. Dateigröße: 200kB"
                    placement="top"
                  >
                    <InformationCircleIcon
                      strokeWidth={2}
                      className="h-10 w-10 text-[#5A5A5A]"
                    ></InformationCircleIcon>
                  </Tooltip>
                </div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      AudioStory
                    </div>
                    {isStoryFileDe ? (
                      <>
                        <div className="col-span-8">
                          {/* @ts-ignore */}
                          <Input
                            className="bg-white"
                            icon={<PencilSquareIcon />}
                            value={
                              "Aktuelle Datei : " +
                              extractFileName(storyFileDeUrl)
                            }
                            readOnly
                          />
                          <audio controls className="mt-2">
                            <source src={storyFileDeUrl} type="audio/mp3" />
                            Your browser does not support the audio tag.
                          </audio>
                        </div>
                        <div className="col-span-1 items-center flex">
                          <TrashIcon
                            strokeWidth={2}
                            className="h-6 w-6 text-[#5A5A5A]  cursor-pointer m-auto"
                            onClick={(e) => {
                              setStoryFileDeUrl("");
                              setIsStoryFileDe(false);
                            }}
                          ></TrashIcon>
                        </div>
                      </>
                    ) : (
                      <div className="col-span-9">
                        <FileInput
                          className="text-[#5A5A5A]"
                          accept="audio/*"
                          id="file-upload"
                          onChange={handleAudioStoryDeChange}
                        />
                        {storySrcDe && (
                          <audio ref={storyRefDe} controls className="mt-2">
                            <source
                              src={storySrcDe as string}
                              type="audio/mp3"
                            />
                            Your browser does not support the audio tag.
                          </audio>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1">
                  <Tooltip content="Video Name" placement="top">
                    <InformationCircleIcon
                      strokeWidth={2}
                      className="h-10 w-10 text-[#5A5A5A]"
                    ></InformationCircleIcon>
                  </Tooltip>
                </div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      Video Überschrift
                    </div>
                    <div className="col-span-9">
                      {/* @ts-ignore */}
                      <Input
                        className="bg-white"
                        icon={<PencilSquareIcon />}
                        value={videoNameDe}
                        onChange={(e) => {
                          setVideoNameDe(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1">
                  <Tooltip content="Vimeo Weblink" placement="top">
                    <InformationCircleIcon
                      strokeWidth={2}
                      className="h-10 w-10 text-[#5A5A5A]"
                    ></InformationCircleIcon>
                  </Tooltip>
                </div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      Video Link
                    </div>
                    <div className="col-span-9">
                      {/* @ts-ignore */}
                      <Input
                        className="bg-white"
                        icon={<PencilSquareIcon />}
                        value={videoLinkDe}
                        onChange={(e) => {
                          setVideoLinkDe(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="text-center m-auto mb-16">
                <img src={AMFlagImg} className="m-auto" />
              </div>
              <h1 className="text-[#5A5A5A] text-3xl font-normal mb-12">
                <br />
              </h1>

              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1"></div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      Name
                    </div>
                    <div className="col-span-9">
                      {/* @ts-ignore */}
                      <Input
                        className="bg-white"
                        icon={<PencilSquareIcon />}
                        value={nameEn}
                        onChange={(e) => {
                          setNameEnError(false);
                          setError(false);
                          setNameEn(e.target.value);
                        }}
                      />
                      {nameEnError ? (
                        <p className="text-red-800">
                          Please fill out this field.
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1"></div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      Kurztext
                    </div>
                    <div className="col-span-9">
                      <Textarea
                        className="bg-white"
                        // @ts-ignore
                        icon={<PencilSquareIcon />}
                        value={descEn}
                        onChange={(e) => {
                          setDescEnError(false);
                          setError(false);
                          setDescEn(e.target.value);
                        }}
                      />
                      {descEnError ? (
                        <p className="text-red-800">
                          Please fill out this field.
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1"></div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      Audio(Kurztext)
                    </div>
                    {isDescAudioFileEn ? (
                      <>
                        <div className="col-span-8">
                          {/* @ts-ignore */}
                          <Input
                            className="bg-white"
                            icon={<PencilSquareIcon />}
                            value={
                              "Aktuelle Datei : " +
                              extractFileName(descAudioFileEnUrl)
                            }
                            readOnly
                          />
                          <audio controls className="mt-2">
                            <source src={descAudioFileEnUrl} type="audio/mp3" />
                            Your browser does not support the audio tag.
                          </audio>
                        </div>
                        <div className="col-span-1 items-center flex">
                          <TrashIcon
                            strokeWidth={2}
                            className="h-6 w-6 text-[#5A5A5A]  cursor-pointer m-auto"
                            onClick={(e) => {
                              setDescAudioFileEnUrl("");
                              setIsDescAudioFileEn(false);
                            }}
                          ></TrashIcon>
                        </div>
                      </>
                    ) : (
                      <div className="col-span-9">
                        <FileInput
                          className="text-[#5A5A5A]"
                          accept="audio/*"
                          id="file-upload"
                          onChange={handleDescAudioEnChange}
                        />
                        {descAudioSrcEn && (
                          <audio ref={descAudioRefEn} controls className="mt-2">
                            <source
                              src={descAudioSrcEn as string}
                              type="audio/mp3"
                            />
                            Your browser does not support the audio tag.
                          </audio>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <h1 className="text-[#5A5A5A] text-3xl font-normal mb-12 mt-12">
                <br />
              </h1>

              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1"></div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      Text
                    </div>
                    <div className="col-span-9">
                      {/* @ts-ignore */}
                      <Input
                        className="bg-white"
                        icon={<PencilSquareIcon />}
                        value={firstTextEn}
                        onChange={(e) => {
                          setFirstTextEn(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1"></div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      Audio
                    </div>
                    {isFirstAudioFileEn ? (
                      <>
                        <div className="col-span-8">
                          {/* @ts-ignore */}
                          <Input
                            className="bg-white"
                            icon={<PencilSquareIcon />}
                            value={
                              "Aktuelle Datei : " +
                              extractFileName(firstAudioFileEnUrl)
                            }
                            readOnly
                          />
                          <audio controls className="mt-2">
                            <source
                              src={firstAudioFileEnUrl}
                              type="audio/mp3"
                            />
                            Your browser does not support the audio tag.
                          </audio>
                        </div>
                        <div className="col-span-1 items-center flex">
                          <TrashIcon
                            strokeWidth={2}
                            className="h-6 w-6 text-[#5A5A5A]  cursor-pointer m-auto"
                            onClick={(e) => {
                              setFirstAudioFileEnUrl("");
                              setIsFirstAudioFileEn(false);
                            }}
                          ></TrashIcon>
                        </div>
                      </>
                    ) : (
                      <div className="col-span-9">
                        <FileInput
                          className="text-[#5A5A5A]"
                          accept="audio/*"
                          id="file-upload"
                          onChange={handleFileEnChange}
                        />
                        {audioSrcEn && (
                          <audio ref={audioRefEn} controls className="mt-2">
                            <source
                              src={audioSrcEn as string}
                              type="audio/mp3"
                            />
                            Your browser does not support the audio tag.
                          </audio>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1"></div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      AudioStory
                    </div>
                    {isStoryFileEn ? (
                      <>
                        <div className="col-span-8">
                          {/* @ts-ignore */}
                          <Input
                            className="bg-white"
                            icon={<PencilSquareIcon />}
                            value={
                              "Aktuelle Datei : " +
                              extractFileName(storyFileEnUrl)
                            }
                            readOnly
                          />
                          <audio controls className="mt-2">
                            <source src={storyFileEnUrl} type="audio/mp3" />
                            Your browser does not support the audio tag.
                          </audio>
                        </div>
                        <div className="col-span-1 items-center flex">
                          <TrashIcon
                            strokeWidth={2}
                            className="h-6 w-6 text-[#5A5A5A]  cursor-pointer m-auto"
                            onClick={(e) => {
                              setStoryFileEnUrl("");
                              setIsStoryFileEn(false);
                            }}
                          ></TrashIcon>
                        </div>
                      </>
                    ) : (
                      <div className="col-span-9">
                        <FileInput
                          className="text-[#5A5A5A]"
                          accept="audio/*"
                          id="file-upload"
                          onChange={handleAudioStoryEnChange}
                        />
                        {storySrcEn && (
                          <audio ref={storyRefEn} controls className="mt-2">
                            <source
                              src={storySrcEn as string}
                              type="audio/mp3"
                            />
                            Your browser does not support the audio tag.
                          </audio>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1"></div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      Video Überschrift
                    </div>
                    <div className="col-span-9">
                      {/* @ts-ignore */}
                      <Input
                        className="bg-white"
                        icon={<PencilSquareIcon />}
                        value={videoNameEn}
                        onChange={(e) => {
                          setVideoNameEn(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-1"></div>
                <div className="col-span-11">
                  <div className="grid grid-cols-12">
                    <div className="col-span-3 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl">
                      Video Link
                    </div>
                    <div className="col-span-9">
                      {/* @ts-ignore */}
                      <Input
                        className="bg-white"
                        icon={<PencilSquareIcon />}
                        value={videoLinkEn}
                        onChange={(e) => {
                          setVideoLinkEn(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-b-4 border-[#5A5A5A] mx-5 mb-20">
          <div className="container m-auto mt-20 pb-12"></div>
        </div>
        <div className="container m-auto mt-12">
          <div>
            <h1 className="text-[#5A5A5A] text-3xl font-normal mb-12">
              POIs zuweisen
            </h1>
            <div className="grid grid-cols-12 gap-4 items-center mb-6">
              <div className="col-span-3"></div>
              <div className="col-span-9">
                {selectedPois.map((poi, index) => {
                  return <Item key={index} item={poi} index={index} />;
                })}
              </div>
            </div>
            {canAddPoi == true ? (
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                <div className="col-span-3"></div>
                <div className="col-span-9">
                  <div className="grid grid-cols-12 gap-4 items-center">
                    <div className="col-span-10">
                      {/* @ts-ignore */}
                      <Select
                        label="Select Poi"
                        className="bg-white"
                        value={selectedPoi}
                        onChange={(value) => handleSelectChange(value)}
                      >
                        {pois
                          .filter((poi) => !selectedPois.includes(poi))
                          .map((poi, index) => (
                            <Option key={index} value={poi.id}>
                              {poi.languages?.de?.name ?? ""}
                            </Option>
                          ))}
                      </Select>
                    </div>
                    <div className="col-span-1">
                      <PlusCircleIcon
                        onClick={plusNewPoi}
                        strokeWidth={2}
                        color="#5A5A5A"
                        className="h-6 w-6 cursor-pointer m-auto"
                      ></PlusCircleIcon>
                    </div>
                    <div className="col-span-1">
                      <TrashIcon
                        onClick={cancelNewPoi}
                        strokeWidth={2}
                        className="h-6 w-6 text-[#5A5A5A]  cursor-pointer m-auto"
                      ></TrashIcon>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="text-center mt-2">
              <div
                className="text-[#5A5A5A] text-xl text-center mt-2 inline-flex items-center"
                onClick={addNewPoi}
              >
                POI hinzufügen
                <PlusCircleIcon
                  strokeWidth={2}
                  color="#5A5A5A"
                  className="h-6 w-6"
                >
                  {" "}
                </PlusCircleIcon>
              </div>
            </div>
          </div>
        </div>
        <div className="border-b-4 border-[#5A5A5A] mx-5 mb-20">
          <div className="container m-auto mt-20 pb-12"></div>
        </div>
        <div className="container m-auto mt-12">
          <h1 className="text-[#5A5A5A] text-3xl font-normal mb-12">Bilder</h1>

          <div className="grid grid-cols-12 gap-4 items-center mb-6">
            <div className="col-span-1">
              <Tooltip
                content="Format: Querformat, max. Dateigröße 200kB"
                placement="top"
              >
                <InformationCircleIcon
                  strokeWidth={2}
                  className="h-10 w-10 text-[#5A5A5A]"
                ></InformationCircleIcon>
              </Tooltip>
            </div>
            <div className="col-span-11"></div>
          </div>
          <div className="grid grid-cols-12 gap-4 items-center mb-6">
            <div className="col-span-1"></div>
            <div className="col-span-11">
              <div className="grid grid-cols-12 gap-4 items-center mb-6">
                {oldImages.map((image, index) => {
                  return <BilderItem key={index} item={image} index={index} />;
                })}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-4 items-center mb-6">
            <div className="col-span-1"></div>
            <div className="col-span-11">
              {images.map((image, index) => {
                return !image.isHidden ? (
                  <div className="grid grid-cols-12 mb-6" key={index}>
                    <div className="col-span-1 flex items-center justify-end pr-2 text-[#5A5A5A] text-xl"></div>
                    <div className="col-span-11">
                      <FileInput
                        className="text-[#5A5A5A]"
                        id="file-upload"
                        accept="image/*"
                        onChange={(e) => handleBilderChange(index, e)}
                      />
                      {image.previewUrl && (
                        <div className="relative mt-2 w-[max-content]">
                          {/* Thumbnail Preview */}
                          <img
                            src={image.previewUrl}
                            alt="Preview"
                            className="w-72 h-auto rounded-lg shadow-lg cursor-pointer transition-transform hover:scale-105"
                            onClick={() => modalToggle(index, true)} // Open modal on click
                          />

                          {/* Full-Size Modal */}
                          {image.isModalOpen && (
                            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                              <img
                                src={image.previewUrl}
                                alt="Full Size Preview"
                                className="max-w-full max-h-full rounded-lg shadow-xl"
                              />
                              <button
                                className="absolute top-4 right-4 bg-white text-black p-2 rounded-full shadow-md"
                                onClick={() => modalToggle(index, false)} // Close modal on click
                              >
                                ✕
                              </button>
                            </div>
                          )}
                          <TrashIcon
                            strokeWidth={2}
                            className="h-6 w-6 text-[#5A5A5A]  cursor-pointer m-auto mt-2 transition"
                            onClick={() => removeImage(index)}
                          ></TrashIcon>
                        </div>
                      )}
                    </div>
                  </div>
                ) : null;
              })}
            </div>
          </div>
          <div className="text-center mt-2">
            <div
              className="text-[#5A5A5A] text-xl text-center mt-2 inline-flex items-center"
              onClick={addNewImage}
            >
              Neues Bild hinzufügen
              <PlusCircleIcon
                strokeWidth={2}
                color="#5A5A5A"
                className="h-6 w-6"
              >
                {" "}
              </PlusCircleIcon>
            </div>
          </div>
        </div>
        <div className="border-b-4 border-[#5A5A5A] mx-5 mb-20">
          <div className="container m-auto mt-20 pb-12"></div>
        </div>
        <div className="container m-auto mt-12">
          <h1 className="text-[#5A5A5A] text-3xl font-normal mb-12">
            Kurzinfo Tourvorschau
          </h1>
          <div className="grid grid-cols-12 gap-4 items-center mb-6">
            <div className="col-span-1">
              <Tooltip
                content="Max. 9 Zeichen (z.B. 1 Stunde und 15 Minuten als „1,25 Std.“, oder 45 Minuten als „45 Min.“)"
                placement="top"
              >
                <InformationCircleIcon
                  strokeWidth={2}
                  className="h-10 w-10 text-[#5A5A5A]"
                ></InformationCircleIcon>
              </Tooltip>
            </div>
            <div className="col-span-11">
              <div className="grid grid-cols-12">
                <div className="col-span-3 flex items-center justify-center pr-2 text-[#5A5A5A] text-xl">
                  Dauer
                </div>
                <div className="col-span-9">
                  {/* @ts-ignore */}
                  <Input
                    className="bg-white"
                    icon={<PencilSquareIcon />}
                    value={durationTime}
                    onChange={(e) => {
                      setDurationTime(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-12 gap-4 items-center mb-6">
            <div className="col-span-1">
              <Tooltip
                content="Max. 5 Zeichen, in km (z.B. 2,5 Kilometer als „2,5 km“)"
                placement="top"
              >
                <InformationCircleIcon
                  strokeWidth={2}
                  className="h-10 w-10 text-[#5A5A5A]"
                ></InformationCircleIcon>
              </Tooltip>
            </div>
            <div className="col-span-11">
              <div className="grid grid-cols-12">
                <div className="col-span-3 flex items-center justify-center pr-2 text-[#5A5A5A] text-xl">
                  Länge
                </div>
                <div className="col-span-9">
                  {/* @ts-ignore */}
                  <Input
                    className="bg-white"
                    icon={<PencilSquareIcon />}
                    value={duration}
                    onChange={(e) => {
                      setDuration(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-4 items-center mb-6">
            <div className="col-span-1">
              <Tooltip
                content="Format: Quadratisch, max. Dateigröße 200kB"
                placement="top"
              >
                <InformationCircleIcon
                  strokeWidth={2}
                  className="h-10 w-10 text-[#5A5A5A]"
                ></InformationCircleIcon>
              </Tooltip>
            </div>
            <div className="col-span-11">
              <div className="grid grid-cols-12">
                <div className="col-span-3 flex items-center justify-center pr-2 text-[#5A5A5A] text-xl">
                  Tour Vorschaubild
                </div>
                {isTourBGFile ? (
                  <>
                    <div className="col-span-8">
                      <div className="relative my-2 w-[max-content]">
                        {/* Thumbnail Preview */}
                        <img
                          src={tourBGFileUrl}
                          alt="Preview"
                          className="w-72 h-auto rounded-lg shadow-lg cursor-pointer transition-transform hover:scale-105"
                          onClick={() => modalToggleBGBasic(true)} // Open modal on click
                        />

                        {/* Full-Size Modal */}
                        {isModalOpenBGBasic && (
                          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                            <img
                              src={tourBGFileUrl}
                              alt="Full Size Preview"
                              className="max-w-full max-h-full rounded-lg shadow-xl"
                            />
                            <button
                              className="absolute top-4 right-4 bg-white text-black p-2 rounded-full shadow-md"
                              onClick={() => modalToggleBGBasic(false)} // Close modal on click
                            >
                              ✕
                            </button>
                          </div>
                        )}
                      </div>
                      {/* @ts-ignore */}
                      <Input
                        className="bg-white"
                        icon={<PencilSquareIcon />}
                        value={
                          "Aktuelle Datei : " + extractFileName(tourBGFileUrl)
                        }
                        readOnly
                      />
                    </div>
                    <div className="col-span-1 items-center flex">
                      <TrashIcon
                        strokeWidth={2}
                        className="h-6 w-6 text-[#5A5A5A]  cursor-pointer m-auto"
                        onClick={(e) => {
                          setTourBGFileUrl("");
                          setIsTourBGFile(false);
                        }}
                      ></TrashIcon>
                    </div>
                  </>
                ) : (
                  <div className="col-span-9">
                    <FileInput
                      className="text-[#5A5A5A]"
                      accept="image/*"
                      id="file-upload"
                      onChange={handleBGImageChange}
                    />
                    {bgImageSrcDe && (
                      <div className="relative mt-2 w-[max-content]">
                        {/* Thumbnail Preview */}
                        <img
                          src={bgImageSrcDe as string}
                          alt="Preview"
                          className="w-72 h-auto rounded-lg shadow-lg cursor-pointer transition-transform hover:scale-105"
                          onClick={() => modalToggleBG(true)} // Open modal on click
                        />

                        {/* Full-Size Modal */}
                        {isModalOpenBG && (
                          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                            <img
                              src={bgImageSrcDe as string}
                              alt="Full Size Preview"
                              className="max-w-full max-h-full rounded-lg shadow-xl"
                            />
                            <button
                              className="absolute top-4 right-4 bg-white text-black p-2 rounded-full shadow-md"
                              onClick={() => modalToggleBG(false)} // Close modal on click
                            >
                              ✕
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-12 gap-4 items-center mb-6">
            <div className="col-span-1">
              <Tooltip
                content="Format: Querformat, max. Dateigröße 200kB"
                placement="top"
              >
                <InformationCircleIcon
                  strokeWidth={2}
                  className="h-10 w-10 text-[#5A5A5A]"
                ></InformationCircleIcon>
              </Tooltip>
            </div>
            <div className="col-span-11">
              <div className="grid grid-cols-12">
                <div className="col-span-3 flex items-center justify-center pr-2 text-[#5A5A5A] text-xl">
                  Video Vorschaubild
                </div>
                {isThumbFile ? (
                  <>
                    <div className="col-span-8">
                      <div className="relative my-2 w-[max-content]">
                        {/* Thumbnail Preview */}
                        <img
                          src={thumbFileUrl}
                          alt="Preview"
                          className="w-72 h-auto rounded-lg shadow-lg cursor-pointer transition-transform hover:scale-105"
                          onClick={() => modalToggleThumbBasic(true)} // Open modal on click
                        />

                        {/* Full-Size Modal */}
                        {isModalOpenThumbBasic && (
                          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                            <img
                              src={thumbFileUrl}
                              alt="Full Size Preview"
                              className="max-w-full max-h-full rounded-lg shadow-xl"
                            />
                            <button
                              className="absolute top-4 right-4 bg-white text-black p-2 rounded-full shadow-md"
                              onClick={() => modalToggleThumbBasic(false)} // Close modal on click
                            >
                              ✕
                            </button>
                          </div>
                        )}
                      </div>
                      {/* @ts-ignore */}
                      <Input
                        className="bg-white"
                        icon={<PencilSquareIcon />}
                        value={
                          "Aktuelle Datei : " + extractFileName(thumbFileUrl)
                        }
                        readOnly
                      />
                    </div>
                    <div className="col-span-1 items-center flex">
                      <TrashIcon
                        strokeWidth={2}
                        className="h-6 w-6 text-[#5A5A5A]  cursor-pointer m-auto"
                        onClick={(e) => {
                          setThumbFileUrl("");
                          setIsThumbFile(false);
                        }}
                      ></TrashIcon>
                    </div>
                  </>
                ) : (
                  <div className="col-span-9">
                    <FileInput
                      className="text-[#5A5A5A]"
                      accept="image/*"
                      id="file-upload"
                      onChange={handleThumbChange}
                    />
                    {thumbSrcDe && (
                      <div className="relative mt-2 w-[max-content]">
                        {/* Thumbnail Preview */}
                        <img
                          src={thumbSrcDe as string}
                          alt="Preview"
                          className="w-72 h-auto rounded-lg shadow-lg cursor-pointer transition-transform hover:scale-105"
                          onClick={() => modalToggleThumb(true)} // Open modal on click
                        />

                        {/* Full-Size Modal */}
                        {isModalOpenThumb && (
                          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                            <img
                              src={thumbSrcDe as string}
                              alt="Full Size Preview"
                              className="max-w-full max-h-full rounded-lg shadow-xl"
                            />
                            <button
                              className="absolute top-4 right-4 bg-white text-black p-2 rounded-full shadow-md"
                              onClick={() => modalToggleThumb(false)} // Close modal on click
                            >
                              ✕
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="border-b-4 border-[#5A5A5A] mx-5 mb-20">
          <div className="container m-auto mt-20 pb-12"></div>
        </div>

        <div className="container m-auto mt-40 mb-40">
          <div className="grid lg:grid-cols-6">
            <div className="col-span-4">
              {/* @ts-ignore */}
              <Checkbox
                checked={published}
                onChange={(e) => setPublished(e.target.checked)}
                label={
                  // @ts-ignore
                  <Typography className="flex font-semibold text-[#5A5A5A]">
                    Veröffentlichen
                  </Typography>
                }
              />
            </div>
            <div>
              <Link to="/tour">
                {/* @ts-ignore */}
                <Button
                  variant="outlined"
                  className="text-[#5A5A5A] px-10 bg-white border-none"
                >
                  Abbrechen
                </Button>
              </Link>
            </div>
            <div>
              {/* @ts-ignore */}
              <Button
                variant="outlined"
                className="text-[#5A5A5A] px-10 bg-white border-none hover:text-red-800"
                onClick={onSubmit}
              >
                Speichern
              </Button>
              {error ? (
                <div className="text-red-800 mt-2">
                  Bitte fülle alle Pflichtfelder aus.
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </>
    </DndProvider>
  );
};

const ItemTypes = {
  CARD: "card",
};

export default EditTourPage;
