import { ChangeEvent, RefObject, SetStateAction } from "react";
const _updateInputFileChange = (
  event: ChangeEvent<HTMLInputElement>,
  setState: (value: SetStateAction<File | null>) => void
) => {
  const files = event.target.files;
  if (!!!files || files.length === 0) {
    setState(null);
    return null;
  }
  const selectedFile = files[0];
  setState(selectedFile);
  return selectedFile;
};

// Audio
export const handleAudioInputFileChange = (
  event: ChangeEvent<HTMLInputElement>,
  setState: (value: SetStateAction<File | null>) => void,
  setAudioSource: (value: SetStateAction<string | ArrayBuffer | null>) => void,
  resetAudio: () => void
): void => {
  const selectedFile = _updateInputFileChange(event, setState);
  if (!selectedFile) return;
  const reader = new FileReader();
  reader.onload = (event) => {
    const audioSrc = event?.target?.result ?? null;
    setAudioSource(audioSrc); // Set audio source
    resetAudio();
  };
  reader.readAsDataURL(selectedFile); // Convert file to data URL
};

export const resetAudio = (ref: RefObject<HTMLAudioElement>) => {
  ref.current?.pause(); // Stop the old audio
  ref.current?.load(); // Reload the new source
};

// Image
export const handleImagesInputFileChange = (
  event: ChangeEvent<HTMLInputElement>,
  index: number,
  setState: (value: SetStateAction<IImages[]>) => void
): void => {
  const files = event.target.files;
  if (!!!files || files.length === 0) return;
  const selectedFile = files[0];
  const reader = new FileReader();
  reader.onloadend = () => {
    // Update state only after the file is read
    setState((prevImages) => {
      const newImages = [...prevImages];
      newImages[index].imageFile = selectedFile;
      newImages[index].previewUrl = reader.result as string;
      return newImages;
    });
  };
  reader.readAsDataURL(selectedFile);
};

export const removeImageFromState = (
  index: number,
  state: IImages[],
  setState: (value: SetStateAction<IImages[]>) => void
) => {
  const newImages = [...state]; // Create a copy of the state array
  newImages[index].isHidden = true;
  setState(newImages);
};

export const updateImageIsModalOpen = (
  images: IImages[],
  index: number,
  isOpen: boolean,
  setState: (value: SetStateAction<IImages[]>) => void
) => {
  const newImages = [...images]; // Create a copy of the state array
  newImages[index].isModalOpen = isOpen;
  setState(newImages);
};
export const updateImageDescriptionChange = (
  images: IImages[],
  index: number,
  event: ChangeEvent<HTMLInputElement>,
  setState: (value: SetStateAction<IImages[]>) => void,
  language: "de" | "en"
) => {
  const newImages = [...images]; // Create a copy of the state array
  if (language === "de")
    newImages[index].imageDescriptionDe = event.target.value;
  // Update the specific index with the new value
  else if (language === "en")
    newImages[index].imageDescriptionEn = event.target.value; // Update the specific index with the new value
  setState(newImages); // Update the state
};

export const handleImageInputFileChange = (
  event: ChangeEvent<HTMLInputElement>,
  setState: (value: SetStateAction<File | null>) => void,
  setSource: (value: SetStateAction<string | ArrayBuffer | null>) => void
): void => {
  const files = event.target.files;
  if (!!!files || files.length === 0) {
    setState(null);
    return;
  }
  const selectedFile = files[0];
  setState(selectedFile);
  const reader = new FileReader();
  reader.onloadend = () => setSource(reader?.result ?? null);
  reader.readAsDataURL(selectedFile);
};

export interface IImages {
  imageFile?: File | null;
  imageUrl?: string;
  previewUrl?: string | null;
  isModalOpen?: boolean;
  isHidden?: boolean;
  // for poi
  imageDescriptionDe?: string;
  imageDescriptionEn?: string;
}
export type IAudio = string | ArrayBuffer | null;
